import React, { useState } from "react";
import "../Faqs/Css/style.css";
import arrowDown from "./Images/arrow-down.png";

const faqsData = [
  {
    id: 1,
    question: "Who are the doctors providing the medical services?",
    answer:
      "Our team consists of highly-qualified General Physicians with a wide range of experience in top medical institutions.",
  },
  {
    id: 2,
    question: "How long will the home consultation last?",
    answer:
      "Home consultations typically last between 30 minutes to an hour, depending on the complexity of the case.",
  },
  {
    id: 3,
    question: "Can I request a visit by the same doctor I consulted before?",
    answer:
      "Yes, you can request the same doctor, subject to their availability.",
  },
  {
    id: 4,
    question: "Can I contact or follow up with the doctor after consultation?",
    answer:
      "Absolutely! Follow-up consultations are available to monitor your recovery.",
  },
  {
    id: 5,
    question: "Do you provide online consultations?",
    answer: "Yes, we offer online consultations for your convenience.",
  },
  {
    id: 6,
    question: "How and when do I make the payment?",
    answer: "Payment can be made online during the appointment booking.",
  },
  {
    id: 7,
    question: "Which other medical services do you provide?",
    answer:
      "We provide a variety of medical services, including routine check-ups, diagnostics, and chronic disease management.",
  },
];

const FAQs = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [viewAll, setViewAll] = useState(false);

  // Toggle specific FAQ answer
  const handleToggle = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  // Toggle between View All and Less
  const toggleViewAll = () => {
    setViewAll(!viewAll);
  };

  return (
    <div className="faqs-container">
      <h2 className="faqs-heading">FAQs</h2>
      <div className="faqs-list">
        {faqsData
          .slice(0, viewAll ? faqsData.length : 3) // Display 3 or all FAQs
          .map((faq) => (
            <div key={faq.id} className="faq-item">
              <div
                className="faq-question"
                onClick={() => handleToggle(faq.id)}
              >
                <h3>{faq.question}</h3>
                <span
                  className={`arrow ${expandedId === faq.id ? "open" : ""}`}
                >
                  <img src={arrowDown} alt="Arrow Down" />
                  {/* &#x25B2; Arrow symbol */}
                </span>
              </div>
              {expandedId === faq.id && (
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
      </div>
      {/* View All / View Less Toggle */}
      <button className="view-all-btn" onClick={toggleViewAll}>
        {viewAll ? "View Less" : "View All"}
      </button>
    </div>
  );
};

export default FAQs;
