import React from "react";
import "./PersonalInfoForm.css";
import Completed from "../../Icons/Completed";
export default function Steps({ currentStep = 2 }) {
  return (
    <div className="stepper">
      <div className={`step ${currentStep > 1 ? "active" : ""}`}>
        {currentStep > 1 ? <Completed /> : <div className="circle">{"1"}</div>}
        <span>Mobile Verification</span>
      </div>
      <div className="line"></div>
      <div onClick={() => {
        let user_id = localStorage.getItem("user_id")
        if (!user_id) {
          return
        }
        window.location.href = ("/book-doctor/step-2?edit=1");
      }} className={`step ${currentStep > 2 ? "active" : ""}`}>
        {currentStep > 2 ? <Completed /> : <div className="circle">2</div>}
        <span>Patient Details</span>
      </div>
      <div className="line"></div>
      <div  onClick={() => {
        let address_id = localStorage.getItem("address_id")
        if (!address_id) {
          return
        }
        window.location.href = ("/book-doctor/step-2?edit=2");
      }}  className={`step ${currentStep > 3 ? "active" : ""}`}>
        {currentStep > 3 ? <Completed /> : <div className="circle">3</div>}
        <span>Location</span>
      </div>
      <div className="line"></div>

      <div onClick={() => {
        let savedAppointment = localStorage.getItem("savedAppointment")
        if (!savedAppointment) {
          return
        }
        window.location.href = ("/book-doctor/step-3");
      }}   className={`step ${currentStep > 4 ? "active" : ""}`}>
        {currentStep > 4 ? <Completed /> : <div className="circle">4</div>}
        <span>Doctor
        & ETA</span>
      </div>
    </div>
  );
}
