import React from 'react';
import '../Comparision/Css/style.scss';

const Comparison = () => {
    return (
        <div className="kyno-container">
            <div className="pricing-section">
                <h3 className='pricing_title'>Consultation Pricing</h3>
                <div className='pricing_card_section'>
                    <div className="pricing-cards">
                        <div className="consulting_card">
                        <div className="price">₹<span className='strike_through'>1499</span><span style={{ color: '#ffb300'}}> 999</span></div>
                            <div className="time">{" "}</div>
                        </div>
                        <div className="consulting_card">
                            <div className="price">₹<span className='strike_through'>1999</span><span style={{ color: '#ffb300'}}> 1499</span></div>
                            <div className="time">Night charges</div>
                        </div>
                    </div>
                    <p className="nursing-text">
                        Nursing services starting at <span className="price-highlight">₹499</span>
                    </p>
                </div>

            </div>
            <div className="comparison-section">
                <h2 className="title">
                </h2>
                <div className="comparison-table">
                    <div className="comparision_type">
                        <div className='comparision_count'>
                            <div className="others-time">5m</div>
                            <div className="kyno-time">30s</div>
                        </div>

                        <div className="label">Booking Time</div>
                    </div>
                    <div className="comparision_type">
                        <div className='comparision_count'>
                            <div className="others-time">40m</div>
                            <div className="kyno-time">0s</div>
                        </div>

                        <div className="label">Roundtrip travel</div>
                    </div>
                    <div className="comparision_type">
                        <div className='comparision_count'>
                            <div className="others-time">20m</div>
                            <div className="kyno-time">0s</div>
                        </div>

                        <div className="label">Wait & billing time</div>
                    </div>
                    <div className="comparision_type">
                        <div className='comparision_count'>
                            <div className="others-time">10m</div>
                            <div className="kyno-time">30m</div>
                        </div>

                        <div className="label">Consultation time</div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Comparison;
