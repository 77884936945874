import React from "react";
import "../Footer/Css/style.scss";
import kenoLogo from './Images/kenoLogo.png';
import facebook from './Images/facebook-logo.png';
import twitter from '../Footer/Images/twitter-logo.png';
import instagram from '../Footer/Images/instagram-logo.png';
import kenoHealthLogo from './Images/kenoHealthLogo.png'
import { Link } from "react-router-dom";

const Footer = () => {
    const currentParams = new URLSearchParams(window.location.search);
    return (
        <>
            <footer className="footer-mobile">
                <div className="footer-content">
                    {/* Logo Section */}
                    <div className="logo">
                        <span className="logo-text">
                            <img src={kenoLogo} alt="" />
                        </span>
                    </div>

                    {/* Description */}
                    <p className="footer-description">
                        India's first 24/7 Doctor At Home Service.<br /> No waiting, no travel, no
                        hassle!<br /> Healthcare, the way it should be.
                    </p>

                    {/* Sitemap Section */}
                    <div className="sitemap">
                        <h3>SITEMAP</h3>
                        <ul className="site_list">
                            <Link to="/about-us"><li>About us</li></Link>
                            <Link to="/provide-services"><li>Services</li></Link>
                            <Link to="/blog"><li>Blog</li></Link>
                            <Link to="/contact-us"><li>Contact us</li></Link>
                            <Link to="/terms-conditions/"><li>Terms and Conditions</li></Link>
                            <Link to="/return-policy"><li>Return Policy</li></Link>
                            <Link to="/privacy-policy/"><li>Privacy Policy</li></Link>
                        </ul>
                    </div>

                    {/* Social Media Icons */}
                    <div className="social-icons">
                        {/* <Link to="https://www.instagram.com/kynohealth/?igshid=YmMyMTA2M2Y" target="_blank">
                            <span className="icon">
                                <img src={facebook} alt="" />
                            </span>
                        </Link>
                        <Link to="https://www.instagram.com/kynohealth/?igshid=YmMyMTA2M2Y" target="_blank">
                            <span className="icon">
                                <img src={twitter} alt="" />
                            </span>
                        </Link>
                        <Link to="https://www.instagram.com/kynohealth/?igshid=YmMyMTA2M2Y" target="_blank">
                            <span className="icon">
                                <img src={instagram} alt="" />
                            </span>
                        </Link> */}

                        <Link to="https://instagram.com/kynohealth?igshid=YmMyMTA2M2Y="><span className="icon">
                            <img src={instagram} alt="" />
                        </span>
                        </Link>
                        <Link to="https://www.linkedin.com/company/kyno-health/">
                            <span className="icon">
                                <img src='/images/LinkedinLogo.svg' alt='logo' />
                            </span>
                        </Link>
                        <Link to="https://twitter.com/Kynohealth"><span className="icon">
                            <img src={twitter} alt="" />
                        </span>
                        </Link>
                        <Link to="https://www.facebook.com/KynoHealth-106490852367301"><span className="icon">
                            <img src={facebook} alt="" />
                        </span>
                        </Link>
                        <Link to="https://youtube.com/@kynohealth">
                            <span className="icon">
                                <img src='/images/YoutubeLogo.svg' alt='logo' />
                            </span>
                        </Link>
                        <Link to="https://wa.me/01206056623">
                            <span className="icon">
                                <img src='/images/WhatsappLogo.svg' alt='logo' />
                            </span>
                        </Link>
                    </div>

                    {/* Footer Copyright */}
                    <p className="footer-copyright">
                        @2025 Kyno Health Pvt. Ltd.
                    </p>
                </div>
            </footer>

            <div className="footer-hero-section">
                <div className="hero-content">
                    <h1>Take the first step to <br></br> better & convenient care</h1>
                    <Link to={`/book-doctor/step-1${currentParams ? '?' + currentParams : ''}`}><button className="hero-button">Book a home visit</button></Link>
                </div>
                <footer className="hero-footer">
                    <div className="footer-content">
                        <div className="logo">
                            <img src={kenoHealthLogo} alt="Kyno Health Logo" />
                        </div>
                        <p className="footer-description-desktop">
                            India’s first 24/7 Doctor At Home Service.<br />No waiting, no travel, no
                            hassle!<br />Healthcare, the way it should be.
                        </p>
                        <p className="footer-copyright">
                            ©2025 Kyno Health Pvt. Ltd.
                        </p>
                    </div>
                    <div className="footer-links">
                        <a href="#about-us">About us</a>
                        <a href="#services">Services</a>
                        <a href="#blog">Blog</a>
                        <a href="#contact-us">Contact us</a>
                        <a href="/terms-conditions/">Terms and Conditions</a>
                        <a href="/return-policy">Return Policy</a>
                        <a href="/privacy-policy/">Privacy Policy</a>
                    </div>
                    <div className="footer-socials">
                        <Link to="https://instagram.com/kynohealth?igshid=YmMyMTA2M2Y="><span className="icon">
                            <img src={instagram} alt="" />
                        </span>
                        </Link>
                        <Link to="https://www.linkedin.com/company/kyno-health/">
                            <span className="icon">
                                <img src='/images/LinkedinLogo.svg' alt='logo' />
                            </span>
                        </Link>
                        <Link to="https://twitter.com/Kynohealth"><span className="icon">
                            <img src={twitter} alt="" />
                        </span>
                        </Link>
                        <Link to="https://www.facebook.com/KynoHealth-106490852367301"><span className="icon">
                            <img src={facebook} alt="" />
                        </span>
                        </Link>
                        <Link to="https://youtube.com/@kynohealth">
                            <span className="icon">
                                <img src='/images/YoutubeLogo.svg' alt='logo' />
                            </span>
                        </Link>
                        <Link to="https://wa.me/01206056623">
                            <span className="icon">
                                <img src='/images/WhatsappLogo.svg' alt='logo' />
                            </span>
                        </Link>
                        {/* <a href="#facebook" aria-label="Facebook">
                            <img src={facebook} alt="Facebook" />
                        </a>
                        <a href="#twitter" aria-label="Twitter">
                            <img src={twitter} alt="Twitter" />
                        </a>
                        <a href="#instagram" aria-label="Instagram">
                            <img src={instagram} alt="Instagram" />
                        </a> */}
                    </div>

                </footer>
            </div>
        </>

    );
};

export default Footer;
