import React, { useState, useEffect } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import BrandButton from "../BrandButton"; // Import the reusable BrandButton
import "./GetMobileNumber.css"; // Import the CSS file
import { SlickCarousel } from "../../booking/landing_10min_withOTP";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FeedbackV2 } from "../../lendingPage/Feedback";
import Success from "../../Icons/Success";

import ENDPOINTS from "../../../Constants/ENDPOINTS.json";
import HappyFamily from "./HappyFamily";
import HeaderLogo from "./HeaderLogo";

const GetMobileNumber = ({ fromHomepage = false }) => {
  const [selectedCountry, setSelectedCountry] = useState({
    value: "+91",
    label: "🇮🇳 +91",
  });
  const urlCity = useParams().city;
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [timer, setTimer] = useState(15);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isGetOtpDisabled, setIsGetOtpDisabled] = useState(true);
  const [isVerifyOtpDisabled, setIsVerifyOtpDisabled] = useState(true);
  const [isVerificationSuccess, setIsVerificationSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlPhone = searchParams.get("phone");
  const urlOrderId = searchParams.get("orderId");
  const paramsCity = searchParams.get("city");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState(urlPhone || "");
  const [showOtp, setShowOtp] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [bookActive, setBookActive] = useState(false);
  const [slottype, setSlotType] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState(urlOrderId || null);
  const [openPopup, setOpenPopup] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [starttimeSelected, setStarttimeSelected] = useState(null);
  const [timeFormat, setTimeFormat] = useState(null);
  const [city, setCity] = useState(urlCity || paramsCity || "");
  const [cityError, setCityError] = useState(false);
  const [requestNonce, setRequestNonce] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [pollingCount, setPollingCount] = useState(0);
  const [pollingError, setPollingError] = useState(null);
  const [tcName, setTcName] = useState("");
  const [tcPhone, setTcPhone] = useState("");
  const [tcEmail, setTcEmail] = useState("");
  const isPlumPage = !!window.location.href.includes("plum");
  const maxPollingAttempts = 5;
  const navigate = useNavigate();
  const [step, setStep] = useState(
    window.location.href.includes("checkout") || (urlPhone && urlOrderId)
      ? 2
      : 1
  );
  const [isTruecallerOpened, setTruecallerOpened] = useState(false);
  const [age, setAge] = useState("");
  const [ageError, setAgeError] = useState(false);
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState(false);
  const [symptom, setSymptom] = useState("");
  const [symptomError, setSymptomError] = useState(false);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [flatNoFloor, setFlatNoFloor] = useState("");
  const [flatNoFloorError, setFlatNoFloorError] = useState(false);
  const [landmark, setLandmark] = useState("");
  const [latlong, setLatLong] = useState("");
  const [APILoader, setAPILoader] = useState(false);
  const [otpUserId, setOtpUserId] = useState("");
  const [appointment, setAppointment] = useState(null);
  const [otpResent, setOtpResent] = useState(false);
  const countryOptions = [{
    value: "+91",
    label: "🇮🇳 IN"
  }]
  // const countryOptions = countryList()
  //   .getData()
  //   .map((country) => ({
  //     value: country.value,
  //     label: `🇮🇳 ${country.value}`, // Replace with real flags
  //   }));

  useEffect(() => {
    if (showOtpScreen && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
  }, [showOtpScreen, timer]);

  useEffect(() => {
    setIsGetOtpDisabled(phone.length !== 10 || !/^\d+$/.test(phone));
  }, [phone]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    let userId = localStorage.getItem("user_id") || sessionStorage.getItem("user_id")
    if (!token) {
      localStorage.clear()
    } else {
      let savedAppointment = localStorage.getItem("savedAppointment")
      if (savedAppointment) {
        savedAppointment = JSON.parse(savedAppointment)
        // localStorage.setItem("persDetails", savedAppointment.patient)
        // localStorage.setItem("locationDetails", savedAppointment.appointment_address)
        // localStorage.setItem("savedDoctorDetails", savedAppointment.doctor)
        navigate(`/book-doctor/step-3?appId=${savedAppointment.id}`)
      } else if (userId) {
        navigate("/book-doctor/step-2");
      } 
    }
  }, []);

  useEffect(() => {
    // Enable Verify OTP button only if all 6 OTP digits are entered
    const isOtpComplete = otp.every((digit) => digit !== "");
    setIsVerifyOtpDisabled(!isOtpComplete);
  }, [otp]);

  useEffect(() => {
    let navigationTimer;
    if (isVerificationSuccess) {
      navigationTimer = setTimeout(() => {
        navigate("/book-doctor/step-2");
      }, 1000);
    }

    return () => {
      // Clear timeout if component unmounts
      if (navigationTimer) {
        clearTimeout(navigationTimer);
      }
    };
  }, [isVerificationSuccess, navigate]);

  const handleGetOtp = () => {
    // Here you would typically send the OTP to the user's mobile number
    handlePayment();
    setShowOtpScreen(true);
    setTimer(15);
    setIsResendDisabled(true);
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    // Auto focus to next input if a digit is entered
    if (value && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };
  function calculateDOB(age) {
    const today = new Date();
    const birthYear = today.getFullYear() - age;
    const dob = new Date(birthYear, today.getMonth(), today.getDate());

    const day = String(dob.getDate()).padStart(2, "0");
    const month = String(dob.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = dob.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const createOrder = async (params) => {
    const myHeaders = new Headers();
    // setAPILoader(true)
    myHeaders.append("Content-Type", "application/json");
    let apiParams = {
      name: name || "NAME_NOT_FILLED",
      mobile: phone,
      url: window.location.href,
      tc_name: tcName,
      tc_email: tcEmail,
      tc_mobile: tcPhone,
      city: city,
      kyno_lead_source: "LANDING_10",
    };
    if (step === 2) {
      apiParams = {
        ...apiParams,
        slottype: slottype,
        starttime: starttimeSelected,
        city: city,
        payment: true,
        orderId: currentOrderId,
        age,
        symptom,
        latlong,
        address,
        flatNoFloor,
        landmark,
      };
      delete apiParams["url"];
    }
    console.log({step, apiParams})
    const raw = JSON.stringify(apiParams);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // return JSON.stringify({})
    try {
      let response = {
        status: 1
      }
      // await fetch(
      //   process.env.REACT_APP_NODE_BASE_URL + "/api/create_order_v2",
      //   requestOptions
      // );
      // response = response.text();
      console.log({response, step})
      if (step === 1) {
        // const myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          data: {
            attributes: {
              country_code: "+91",
              mobile_number: phone.toString(),
            },
          },
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          // mode: 'no-cors',
          redirect: "follow",
        };

        let res = await fetch(
          process.env.REACT_APP_KMS_BASE_URL + "/api/login/logins/login_otp",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            console.log({ result });
            const { user_id } = JSON.parse(result);
            setOtpUserId(user_id);
            localStorage.setItem("user_id", user_id);
            setAPILoader(false);
          })
          .catch((error) => {
            setAPILoader(false);
            console.error(error);
          });
        // console.log({otpResponseApi})
      }
      // setAPILoader(false)
      // let response = JSON.stringify({data: { grandTotal: 1, orderId:"123213", secret:"12312312" }})
      // const { data: { grandTotal, orderId, secret } } = apiData;
      return response;
    } catch (error) {
      setAPILoader(false);
    }
  };

  const handlePayment = async () => {
    console.log("coming", step, name, phone, nameError, phoneError);
    setAPILoader(true);

    // if (!name) {
    //     setNameError("Name is mandatory")
    // }
    if (!phone) {
      setPhoneError("Phone Number is mandatory");
    }
    // if (step === 2) {
    //     if (!city) {
    //         setCityError(true);
    //         return
    //     } else {
    //         setCityError(false)
    //     }
    // }
    if (phoneError || !phone || nameError) {
      return;
    }

    window.dataLayer.push({ event: "initiate_checkout" });
    await createOrder();
    // console.log({ apiData });
    // if (apiData.status === 0) {
    //   setPhoneError(apiData.msg);
    //   return;
    // }
    // const {
    //   data: { grandTotal, orderId, secret },
    // } = apiData;
    // let grandTotal = 0, orderId = "tetst", secret = "11"
    // if ((urlCity || paramsCity)) {

    //     // navigate("/new-booking/checkout?" + "phone=" + phone + "&orderId=" + orderId + "&city=" + (urlCity || paramsCity))
    // } else {
    //     // navigate("/new-booking/checkout?" + "phone=" + phone + "&orderId=" + orderId)
    // }

    setPaymentStatus("pending");
    // setCurrentOrderId(orderId); // orderId
    if (step === 1 && !showOtp) {
      setShowOtp(true);
      // setStep(2)
      // setName("")
      return;
    } else if (step === 1 && showOtp) {
      // if (fromHomepage) {
      //   navigate("/booking-v3?" + "phone=" + phone + "&orderId=" + orderId);
      // }
      setShowOtp(false);
      setStep(2);
      setName("");
    }
    await handleUpdateUserProfile();
    // setPaymentStatus("success")
    // console.log({ order, name, phone })
    return;
  };

  const handleResendOtp = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    setTimer(15);
    // Show OTP resent message
    setOtpResent(true);

    // Hide the message after 3 seconds
    setTimeout(() => {
      setOtpResent(false);
    }, 3000);

    const raw = JSON.stringify({
      data: {
        attributes: {
          user_id: localStorage.getItem("user_id"),
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_KMS_BASE_URL + "/api/login/logins/resend_otp",
        requestOptions
      );

      const result = await response.text();
      console.log({ result });

      return result;
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const handleUpdateUserProfile = async () => {
    {
      const myHeaders = new Headers();
      myHeaders.append("token", localStorage.getItem("token"));
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        data: {
          attributes: {
            first_name: name,
            date_of_birth: calculateDOB(age),
            gender: gender,
          },
        },
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      // handleError(otpResponseApi)
      try {
        let otpResponseApi = await fetch(
          process.env.REACT_APP_KMS_BASE_URL +
            "/api/account/profiles/update_profile",
          requestOptions
        )
          .then((response) => response.text())
          .then(async (result) => {
            const profileResponse = JSON.parse(result);
            console.log({ profileResponse });
            // const { token } = otpResponse
            console.log(result);
            // setPaymentStatus("success")
            // await handleKMSSaveAddress();
            return profileResponse;
          })
          .catch((error) => setOtpError(error.errors));
        setAPILoader(false);
      } catch (error) {
        console.log(error);
      }
      return true;
    }
  };

  const handleSubmitOtp = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const enteredOtp = otp.join("");

    setAPILoader(true);
    setOtpError(null); // Clear any previous errors

    const raw = JSON.stringify({
      data: {
        attributes: {
          user_id: localStorage.getItem("user_id"),
          user_role: "Customer",
          otp_code: parseInt(enteredOtp),
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_KMS_BASE_URL +
          "/api/login/logins/login_otp_confirmation",
        requestOptions
      );

      const result = await response.text();
      const otpResponse = JSON.parse(result);

      if (otpResponse.token) {
        // OTP is correct
        localStorage.setItem("token", otpResponse.token);
        localStorage.setItem("phone", phone);
        setShowOtp(false);

        // We'll handle navigation with the useEffect
        return true; // Return success status
      } else {
        // OTP is incorrect
        setOtpError(true);
        return false; // Return failure status
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpError(true);
      return false; // Return failure status
    } finally {
      setAPILoader(false);
    }
  };

  // Then update handleVerifyOtp to use the returned value
  const handleVerifyOtp = async () => {
    const enteredOtp = otp.join("");
    const isSuccessful = await handleSubmitOtp(); // Get success/failure status

    if (isSuccessful) {
      // Only set success if verification was successful
      setIsVerificationSucess(true);
      // Navigation will be handled by the useEffect
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      // Move focus to the previous input on backspace if the current input is empty
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleMobileChange = (e) => {
    // const value = e.target.value;
    // // Allow only numbers and limit to 10 digits
    // if (/^\d*$/.test(value) && value.length <= 10) {
    //   setPhone(value);
    // }
    setPhone("");
    window.location.href = "/book-doctor/step-1";
  };

  const handleValidation = (inputValue, type, errorFun) => {
    if (type === "flatNoFloor" || type === "symptom") {
      if (!inputValue) {
        errorFun("Invalid! Field is mandatory");
        return;
      } else {
        errorFun("");
      }
    } else if (type !== "phone" && type !== "otp") {
      // Simple name validation (only letters and spaces allowed)
      const nameRegex = /^[a-zA-Z\s]+$/;
      if (!nameRegex.test(inputValue) && inputValue) {
        errorFun("Invalid! Only letters and spaces are allowed.");
      } else {
        errorFun("");
      }
    } else if (type === "phone") {
      // Simple phone number validation (only digits, length 10)
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(inputValue)) {
        setPhoneError("Invalid phone number. Must be 10 digits.");
      } else {
        setPhoneError("");
      }
    } else if (type === "otp") {
      // Simple phone number validation (only digits, length 10)
      const otpRegex = /^[0-9]{4}$/;
      if (!otpRegex.test(inputValue)) {
        setOtpError("Invalid OTP. Must be 4 digits.");
      } else {
        setOtpError("");
      }
    }
  };
  const removeLastCharacter = (str) => {
    return str.slice(0, -1);
  };

  const handlePhoneChange = (e) => {
    // const phoneValue = event.target.value;
    const inputValue = e.target.value;
    // Only allow digits (0-9) to be entered
    if (!/^\d*$/.test(inputValue) || inputValue.length > 10) {
      setPhone(removeLastCharacter(inputValue));
      return;
    }
    setPhone(inputValue);
    setStep(1);
    setShowOtp(false);
    setOtp(["", "", "", ""]);
    handleValidation(inputValue, "phone");
    // setPhoneError(!/^\d{10}$/.test(phoneValue)); // Validate phone number as 10 digits
  };

  const handleChangeNumber = () => {
    // Go back to the mobile number input screen
    setShowOtpScreen(false);
    setOtp(["", "", "", ""]); // Reset OTP input
    setTimer(15); // Reset timer
    setIsResendDisabled(true); // Reset resend button
  };

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!showOtpScreen && !isGetOtpDisabled) {
        handleGetOtp(); // Trigger Get OTP on Enter
      } else if (showOtpScreen && !isVerifyOtpDisabled) {
        handleVerifyOtp(); // Trigger Verify OTP on Enter
      }
    }
  };

  return (
    <div className="mobile-container" onKeyDown={handleKeyPress}>
      <HeaderLogo />
      <div
        className="booking_slot_new_flow booking_slot_new_flow-v2"
        style={{
          backgroundColor: "#fff",
          width: "100%",
          maxWidth: "500px",
          margin: "0px ",
        }}
      >
        <SlickCarousel />
        {isVerificationSuccess ? (
          <div className="success-container">
            <Success />
            <div>
              <p className="success-text-typo-1">Congratulations!!</p>
              <p className="success-text-typo-2">
                You're successfully verified
              </p>
            </div>
          </div>
        ) : (
          <div className="second-component-wrapper">
            <h2>Get an experienced doctor at home in 20 minutes</h2>
            <h3>Book your Home Visit Now!</h3>

            <>
              {!showOtpScreen ? (
                <>
                  <p className="mobile-sub-text">Mobile Number</p>
                  <div className="mobile-input-wrapper">
                    {/* Country Code Selector */}
                    <div className="select-container">
                      <Select
                        options={countryOptions}
                        value={selectedCountry}
                        onChange={setSelectedCountry}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "none",
                            background: "transparent",
                            boxShadow: "none",
                            fontSize: "16px",
                          }),
                        }}
                      />
                    </div>
                    <div className="input-wrapper">
                      {/* Mobile Input */}
                      <input
                        type="tel"
                        maxLength="10"
                        value={phone}
                        onChange={handlePhoneChange}
                        onKeyDown={handleKeyPress} // Add keydown listener
                        placeholder="Enter Mobile Number"
                        className="mobile-input"
                      />

                      {/* Get OTP Button */}
                      <BrandButton
                        label="Get OTP"
                        onClick={handleGetOtp}
                        disabled={isGetOtpDisabled}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="verification-text">Verification Code</p>
                  <p className="enter-digit">
                    {otpError
                      ? "Retry , Incorrect OTP!"
                      : "Enter the 4 digit sent to your Phone Number"}
                  </p>
                  <p className="enter-digit mobile">
                    {mobile}{" "}
                    <button
                      onClick={() => handleMobileChange()}
                      className="change-btn"
                    >
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33325 1.8335H5.99992C2.66659 1.8335 1.33325 3.16683 1.33325 6.50016V10.5002C1.33325 13.8335 2.66659 15.1668 5.99992 15.1668H9.99992C13.3333 15.1668 14.6666 13.8335 14.6666 10.5002V9.16683"
                          stroke="#179D82"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.6933 2.5135L5.43992 7.76684C5.23992 7.96684 5.03992 8.36017 4.99992 8.64684L4.71325 10.6535C4.60659 11.3802 5.11992 11.8868 5.84659 11.7868L7.85325 11.5002C8.13325 11.4602 8.52659 11.2602 8.73325 11.0602L13.9866 5.80684C14.8933 4.90017 15.3199 3.84684 13.9866 2.5135C12.6533 1.18017 11.5999 1.60684 10.6933 2.5135Z"
                          stroke="#179D82"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.93994 3.2666C10.3866 4.85994 11.6333 6.1066 13.2333 6.55994"
                          stroke="#179D82"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </p>
                  <div className="otp-input-wrapper">
                    <div className="otp-boxes">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          id={`otp-input-${index}`}
                          type="tel"
                          maxLength="1"
                          pattern="\d*"
                          inputMode="numeric"
                          value={digit}
                          onChange={(e) =>
                            handleOtpChange(index, e.target.value)
                          }
                          onKeyDown={(e) => {
                            handleKeyDown(index, e);
                            handleKeyPress(e);
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            const pastedData = e.clipboardData.getData("text").trim();
                      
                            if (!/^\d+$/.test(pastedData)) return; // Ensure only digits are pasted
                      
                            const otpArray = pastedData.split("").slice(0, otp.length); // Limit to the OTP length
                            const newOtp = [...otp];
                      
                            // Calculate the start position based on the focused input
                            let startIndex = index;
                      
                            // Fill only available slots from the paste position
                            for (let i = 0; i < otpArray.length && startIndex + i < otp.length; i++) {
                              newOtp[startIndex + i] = otpArray[i];
                            }
                      
                            setOtp(newOtp);
                          }}
                          className={`otp-input ${
                            digit ? "otp-input-have-value " : ""
                          }`}
                        />
                      ))}
                    </div>
                    <p className="resend-text">
                      {otpResent ? (
                        <span style={{ color: "#179D82", fontWeight: "500" }}>
                          OTP resent!
                        </span>
                      ) : (
                        <>
                          Didn't receive code?{" "}
                          <span
                            className="resend-timer-text"
                            onClick={timer > 0 ? undefined : handleResendOtp}
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: "3px",
                              cursor: timer > 0 ? "default" : "pointer",
                              color: "#179D82",
                              pointerEvents: timer > 0 ? "none" : "auto",
                              opacity: timer > 0 ? 0.5 : 1,
                            }}
                          >
                            Resend{" "}
                            {timer > 0 &&
                              `(00:${timer < 10 ? `0${timer}` : timer})`}
                          </span>
                        </>
                      )}
                    </p>

                    <BrandButton
                      label="Verify OTP"
                      onClick={handleVerifyOtp}
                      disabled={isVerifyOtpDisabled}
                      className={"verify-otp-btn"}
                    />
                  </div>
                </>
              )}
            </>
          </div>
        )}

        <HappyFamily />

        <FeedbackV2 slidesPerViewMobile={1} slidesPerViewDesktop={1} />
      </div>
    </div>
  );
};

export default GetMobileNumber;
