import React from "react";

export default function Success() {
  return (
    <svg
      width="101"
      height="109"
      viewBox="0 0 101 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.9326 21.5992C79.2899 21.0011 73.7365 19.7455 68.3854 17.858C63.4766 16.1235 58.7674 13.8691 54.338 11.1331C53.1827 10.426 51.8545 10.0518 50.5 10.0518C49.1455 10.0518 47.8173 10.426 46.662 11.1331C42.2327 13.8691 37.5234 16.1236 32.6146 17.858C27.2635 19.7455 21.7101 21.0011 16.0674 21.5992C14.2574 21.7856 12.5808 22.6367 11.362 23.9877C10.1431 25.3387 9.46855 27.0937 9.46875 28.9133V50.7798C9.47226 58.8471 11.6315 66.7669 15.7231 73.7197C19.8147 80.6724 25.69 86.4054 32.7408 90.3255L46.9229 98.2035C48.0158 98.8154 49.2475 99.1367 50.5 99.1367C51.7525 99.1367 52.9842 98.8154 54.0771 98.2035L68.2592 90.3255C75.3101 86.4054 81.1853 80.6724 85.2769 73.7197C89.3685 66.7669 91.5277 58.8471 91.5312 50.7798V28.9133C91.5315 27.0937 90.8569 25.3387 89.638 23.9877C88.4192 22.6367 86.7426 21.7856 84.9326 21.5992Z"
        fill="#46CC6B"
      />
      <path
        d="M92.8786 14.1289C85.9338 13.3971 79.0988 11.8608 72.5128 9.55132C66.4712 7.42911 60.6752 4.67066 55.2237 1.32304C53.8018 0.457853 52.1671 0 50.5 0C48.8329 0 47.1982 0.457853 45.7763 1.32304C40.3248 4.67073 34.5288 7.42918 28.4872 9.55132C21.9012 11.8608 15.0662 13.3971 8.12144 14.1289C5.89374 14.357 3.83027 15.3983 2.33013 17.0513C0.829984 18.7043 -0.000249256 20.8517 5.61336e-08 23.078V49.8328C0.00431707 59.7036 2.66189 69.394 7.69769 77.901C12.7335 86.408 19.9646 93.4227 28.6426 98.2191L46.0974 107.858C47.4426 108.607 48.9584 109 50.5 109C52.0416 109 53.5574 108.607 54.9026 107.858L72.3574 98.2191C81.0354 93.4227 88.2665 86.408 93.3023 77.901C98.3381 69.394 100.996 59.7036 101 49.8328V23.078C101 20.8517 100.17 18.7043 98.6699 17.0513C97.1697 15.3983 95.1063 14.357 92.8786 14.1289Z"
        fill="#46CC6B"
        fill-opacity="0.16"
      />
      <path
        d="M47.5543 68.1054C46.3174 68.108 45.123 67.654 44.2002 66.8303L32.2065 56.1705C31.6901 55.7362 31.266 55.2027 30.9592 54.6016C30.6524 54.0006 30.4692 53.3441 30.4204 52.6711C30.3717 51.9981 30.4582 51.3221 30.6751 50.683C30.892 50.044 31.2347 49.455 31.683 48.9506C32.1314 48.4463 32.6763 48.037 33.2855 47.7468C33.8947 47.4566 34.5559 47.2914 35.2301 47.261C35.9042 47.2307 36.5776 47.3358 37.2104 47.57C37.8432 47.8043 38.4227 48.163 38.9146 48.625L47.2344 56.0148L63.8574 38.2809C64.7738 37.3049 66.04 36.7323 67.3782 36.6889C68.7163 36.6455 70.017 37.1348 70.9947 38.0495C71.9726 38.9655 72.5467 40.2323 72.5909 41.5714C72.6351 42.9106 72.1457 44.2125 71.2304 45.191L51.2408 66.5104C50.7695 67.0165 50.1989 67.4197 49.5645 67.6949C48.9301 67.9701 48.2458 68.1113 47.5543 68.1096V68.1054Z"
        fill="white"
      />
    </svg>
  );
}
