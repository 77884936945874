import React from "react";

export default function Star() {
  return (
    <svg
      viewBox="0 0 51 48"
      className="widget-svg"
      style={{
        width: "15px",
        height: "15px",
        transition: "transform 0.2s ease-in-out 0s",
        marginBottom: "4px",
      }}
    >
      <path
        className="star"
        d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
        style={{
          fill: "rgb(255, 204, 0)",
          transition: "fill 0.2s ease-in-out 0s",
        }}
      ></path>
    </svg>
  );
}
