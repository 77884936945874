import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css'
import Footer from '../../lendingPage/Footer';


function index({isBookSlot, isHideSticky, isPaidPage, customCallus, srNumber="01206056626,  01206056627, 01206056628, 01206056629"}) {
  return (
    <section className="footer">
<div className="container">

<div className="row">



<div className="col-md-6">
  <div className='fooet'>
<img src='/images/Kyno-footer-logo.png' alt='logo'/> </div>
  
  <div className="row">
  <div className="col-md-4 col-6">
  <p><span><img src='/images/Phone.svg' alt='logo'/></span><a href={`tel:${customCallus ? customCallus : isPaidPage ? '+919355023577' : srNumber}`}>{customCallus ? customCallus : isPaidPage ? '+91-9355023577' : srNumber}</a></p></div>
  <div className="col-md-3 col-6">
  <p><span><img src='/images/email.svg' alt='logo'/></span> support@kynohealth.com</p></div>
  </div>

  <div className="socials hidd-m">
  <Link to="https://instagram.com/kynohealth?igshid=YmMyMTA2M2Y="><img src='/images/InstagramLogo.svg' alt='logo'/></Link>
  <Link to="https://www.linkedin.com/company/kyno-health/"><img src='/images/LinkedinLogo.svg' alt='logo'/></Link>
  <Link to="https://twitter.com/Kynohealth"><img src='/images/TwitterLogo.svg' alt='logo'/></Link>
  <Link to="https://www.facebook.com/KynoHealth-106490852367301"><img src='/images/FacebookLogo.svg' alt='logo'/></Link>
  <Link to="https://youtube.com/@kynohealth"><img src='/images/YoutubeLogo.svg' alt='logo'/></Link>
  <Link to="https://wa.me/01206056623"><img src='/images/WhatsappLogo.svg' alt='logo'/></Link>
  <Link to="/"></Link>
  <p className='copyrgt'><a href="/terms-conditions/">Terms and Conditions</a> <a href="/return-policy/">Return Policy</a> <a href="/privacy-policy/">Privacy Policy</a> <a href="/locations/store-locator/">Locate Us</a></p><p><a href="/booking">Book An Appointment</a></p>

<p className='copyrgt text-center'>Copyright @2023 KYNO Health | Trademark of Huami Technologies Private Limited</p>
</div>

</div>

<div className="col-md-3">

<ul>
  <li><Link to="/doctor-home-visit-noida">Doctor home visit noida</Link></li>
  <li><Link to="/doctors-near-me">Doctors near me</Link></li>
  <li><Link to="/iv-therapy">Iv therapy</Link></li>
  <li><Link to="/doctor-on-call-home-visit">Doctors on call home visit</Link></li>
  
 

</ul>
</div>

<div className="col-md-3">
<ul>
<li><Link to="/doctor-for-fever-treatment">Doctors for fever treatment</Link></li>
  {/* <li><Link to="/physician-near-me">Physician near me</Link></li> */}
  <li><Link to="/schedule-doctor-appointment">Schedule doctor appointment</Link></li>
  <li><Link to="/treatment-for-bed-sore">Treatment for bed sore</Link></li>
  <li><Link to="/twenty-four-hour-visiting-doctor">Twenty four hour visiting doctor</Link></li>
  

</ul>
</div>

<div className='col-md-12 d-sm-none d-md-none d-lg-none'>

<div className="socials">
<a href="https://instagram.com/kynohealth?igshid=YmMyMTA2M2Y="><img src='/images/InstagramLogo.svg' alt='logo'/></a>
<a href="https://www.linkedin.com/company/kyno-health/"><img src='/images/LinkedinLogo.svg' alt='logo'/></a>
<a href="https://twitter.com/Kynohealth"><img src='/images/TwitterLogo.svg' alt='logo'/></a>
<a href="https://www.facebook.com/KynoHealth-106490852367301"><img src='/images/FacebookLogo.svg' alt='logo'/></a>
<a href="https://youtube.com/@kynohealth"><img src='/images/YoutubeLogo.svg' alt='logo'/></a>
<a href="https://wa.me/01206056623"><img src='/images/WhatsappLogo.svg' alt='logo'/></a>
<p className='copyrgt'><a href="/terms-conditions/">Terms and Conditions</a> | <a href="/terms/">Return Policy</a> | <a href="/terms/">Privacy Policy</a> | <a href="/booking">Book An Appointment</a></p>
<p className='copyrgt'>Copyright @2024 KYNO Health | Trademark of Huami Technologies Private Limited </p>
<div></div>
</div>
</div>

</div>

<div className='foot-stick'>
  {/*<a href='/booking_form'>Book appointment</a>*/} {/*For Book Appointment*/}
  {!isHideSticky ? isBookSlot ? <a href='/booking'><span>Home page</span></a> : <a href={`tel:${customCallus ? customCallus : "01206056623"}`}><i className='phone-icon fa fa-phone'></i><span>Call Us</span></a> : null} {/*For Call*/}
</div>

</div>
</section>
  )
}

export { index}

export default Footer