import React from "react";
import '../TopPhysicians/Css/style.css';
import d1 from "./Images/d1.jpeg"
import c1 from "./Images/c1.jpg"
import d2 from "./Images/d2.jpeg"
import c2 from "./Images/c2.jpg"
import d3 from "./Images/d3.jpg"
import c3 from "./Images/c3.jpeg"
import d4 from "./Images/d4.jpg"
import c4 from "./Images/c4.jpeg"
import d5 from "./Images/d5.jpg"
import c5 from "./Images/c5.png"
import d6 from "./Images/d6.jpeg"
import c6 from "./Images/c6.png"
import d7 from "./Images/d7.jpeg"
import c7 from "./Images/c7.jpeg"
import d8 from "./Images/d8.jpeg"
import c8 from "./Images/c8.jpeg"
import d9 from "./Images/d9.png"
import c9 from "./Images/c9.jpeg"
import d10 from "./Images/d10.jpeg"
import c10 from "./Images/c10.png"
import d11 from "./Images/d11.jpg"
import c11 from "./Images/c11.png"
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import doctorImage from './Images/DocImage.png';

// import degree from './Images/degree.png';

const physicians = [
  { id: 5, frontImg: d5, backImg: c5 , name: "Dr Rajeena", degreeName: "ESIC Medical College, Faridabad", exp: '14'},
  { id: 2, frontImg: d2, backImg: c2 , name: "Dr Ankesh", degreeName: "Medanta Institute of Education and Research", exp: '10'},
  { id: 11, frontImg: d11, backImg: c11, name: "Dr Vivek", degreeName: "AIIMS, Delhi", exp: '8'},
  { id: 10, frontImg: d10, backImg: c10 , name: "Dr. Abhishek K", degreeName: "JJM Medical College, Karnataka", exp: '8'},
  { id: 4, frontImg: d4, backImg: c4 , name: "Dr Ashwani", degreeName: "University College of Medical Sciences, Delhi", exp: '7'},
  { id: 8, frontImg: d8, backImg: c8 , name: "Dr Shalini", degreeName: "GSL Medical College, Haryana", exp: '7'},
  { id: 6, frontImg: d6, backImg: c6 , name: "Dr Prabhu", degreeName: "Kongunadu Institute of Allied Health Science, Tamil Nadu", exp: '6'},
  { id: 9, frontImg: d9, backImg: c9 , name: "Dr Shashank", degreeName: "Hind Institute of Medical Sciences, UP", exp: '6'},
  { id: 1, frontImg: d1, backImg: c1 , name: "Dr Aditya", degreeName: "NDMC Hospital, Delhi", exp: '5'},
  { id: 3, frontImg: d3, backImg: c3 , name: "Dr Abhishek L", degreeName: "Government Medical College, Karnataka", exp: '5'},
  { id: 7, frontImg: d7, backImg: c7 , name: "Dr Sanjeev", degreeName: "PGIMS, Rohtak", exp: '5'},
    // { id: 12, frontImg: doctorImage, backImg: degree },
  ];

function TopPhysicians(){
    return (
        <div className="top-physicians">
          <h2>How we choose our Doctors / Screening</h2>
          <ContentSwiper />
          {/* <p>
            Our doctors come with years of experience at top institutes
            & hospitals such as Fortis, Medanta, AIIMS, Safdarjung, etc.
          </p> */}
          <div className="physicians-grid">
            {physicians.map((doc) => (
              <div key={doc.id} className="flip-container">
                <div className="flipper">
                  <div className="front">
                    <img style={doc.id === 2 || doc.id === 6 ? { transform: "rotate(90deg)"}: {}} src={doc.frontImg} alt="Doctor" />
                  </div>
                  <div className="back">
                  <div className="doctor_name">{doc.name}</div>
                  <div className="degree_name">{doc.exp}+ years experience</div>
                    {/* <img src={doc.backImg} alt="Medical Logo" /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
}


export function ContentSwiper() {
  const pagination = {
    clickable: true,
    position: "top",
    renderBullet: function (index, className) {
      return ('<span class="swiper_paginations ' + className + '">' + (index + 1) + '</span>')
      // '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  return (
    <>
      <Swiper
        pagination={pagination}
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        <SwiperSlide>{sliderContent("Highly Qualified","Every Kyno doctor is a graduate from India’s most prestigious medical institutions & certified by the Medical Council of India (MCI). With a strong academic background, they provide you exceptional care.")}</SwiperSlide>
        <SwiperSlide>{sliderContent("Highly Experienced","Our doctors bring a minimum of 5 years of experience from top government hospitals like AIIMS, Safdarjung & private hospitals like Medanta, Fortis, Apollo, etc. With at least 20,000 cases handled, they are equipped to manage all your medical requirements.")}</SwiperSlide>
        <SwiperSlide>{sliderContent("Thoroughly Screened","Each doctor undergoes a meticulous onboarding process that includes more than 10 assessments & detailed interviews. They complete 30 days of rigorous ground training, participate in upskilling programs to guarantee excellence in fields like Fever Management, Critical Care, Geriatric Care(Old Age), Emergency Care, Chronic Management & Pain Management.")}</SwiperSlide>
        <SwiperSlide>{sliderContent("Supervised by Experts","Every doctor at Kyno is supervised by our dedicated Medical Excellence (MedEx) team, ensuring adherence to strict medical protocols. This team audits prescriptions, oversees patient cases & ensures that every consultation reflects our commitment to unmatched quality and care.")}</SwiperSlide>
        <SwiperSlide>{sliderContent("Truly Family-Oriented","Kyno doctors prioritize your comfort by dedicating a minimum of 30 minutes to every consultation. They conduct thorough examinations, communicate fluently in English and local languages, and ensure your visit is relaxed, unrushed, and reassuring.")}</SwiperSlide>
      </Swiper>
    </>
  );
}

function sliderContent (heading, description) {
  return (
    <div style={{ padding: "20px",
      fontSize: "14px"}}>
        <div className="slider_heading">
          {heading}
        </div>
        <div className="slider_desc">
          {description}
        </div>
    </div>
  )
}

export default TopPhysicians;