import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function BookDoctor() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/book-doctor/step-1", { replace: true });
  }, [navigate]);

  return <></>;
}
