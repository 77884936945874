import React from "react";
import "./PersonalInfoForm.css";

export default function PriceBanner() {
  const subtotal =
    new Date().getHours() >= 7 && new Date().getHours() < 19 ? 999 : 1499;

  return (
    <div className="book-fee-banner">
      <p className="book-fee-text-typo-1">Pay securely at checkout.</p>
      <p className="book-fee-text-typo-2">
        Consultation Fees:&nbsp;
        {subtotal === 999 ? (
          <>
            <span className="text-1999">₹1499</span>
            <span className="text-1499">₹{subtotal}</span>
          </>
        ) : (
          <>
            <span className="text-1999">₹1999</span>
            <span className="text-1499">₹{subtotal}</span>
          </>
        )}
      </p>
    </div>
  );
}
