import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';
import logo from '../Header/images/kyno.svg'

function Header({ isBookSlot, hideHamburger=false, hideBookingCta=false }) {
    console.log({hideHamburger, hideBookingCta})
    const currentParams = new URLSearchParams(window.location.search);
    const [isVisible, setIsVisible] = useState(true);
    const [headerActive, setHeaderActive] = useState(false)
  let lastScrollY = window.scrollY;

  const handleScroll = () => {
    if (window.scrollY > lastScrollY && window.scrollY > 100) {
      // User is scrolling down, hide the navbar
      window.innerWidth > 768 && setIsVisible(false);
      window.scrollY > 100 && window.innerWidth < 769 ? setHeaderActive(true) : setHeaderActive(false)
    } else {
      // User is scrolling up, show the navbar
      setIsVisible(true);
      window.scrollY < 100 && setHeaderActive(false)
    }
    lastScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return (
        <>
            <div className={`header_main-menu ${isVisible ? '' : 'hidden'}`}>
                <div className='container'>
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to={`/?source=${window.location.pathname.split("/").join("")}`}>
                                <Image
                                    src={logo}
                                    webp={logo}
                                    title='Kyno Health Logo'
                                    alt='headLogoImg'
                                />
                            </Link>
                            <div style={{ display:"flex"}}>

                            {!hideBookingCta && <Link className={`header_button ${headerActive ? 'active' : ''}`} to={`/book-doctor/step-1${currentParams ? '?' + currentParams : ''}`}>Book Home visit</Link>}
                            {!hideHamburger && <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>}
                            </div>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                <div id="navbarSupportedContent">

                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                                        {!hideHamburger && <li className="nav-item">
                                            <Link className="nav-link" to="/provide-services">Services</Link>
                                        </li>}

                                        {!hideHamburger && <li className="nav-item">
                                            <Link className="nav-link" to="/about-us">About Us</Link>
                                        </li>}

                                        {!hideHamburger && <li className="nav-item">
                                            <Link className="nav-link" to="/blog">Blog</Link>
                                        </li>}

                                        {!hideHamburger && <li className="nav-item">
                                            <Link className="nav-link" to="/contact-us">Contact Us</Link>
                                        </li>}
                                        {!hideBookingCta && <li className="nav-item butn">
                                            <Link className="nav-link" to={`/book-doctor/step-1${currentParams ? '?' + currentParams : ''}`}>Book Home visit</Link>
                                        </li>}
                                        {/* {!isBookSlot ?
                                            <li className="nav-item butn">
                                                <Link className="nav-link" to={`tel:${customCallus ? customCallus : isPaidPage ? '+919355023577' : '01206056623'}`}>Call: {customCallus ? customCallus : isPaidPage ? '93550 23577' : '01206056623'}</Link>
                                            </li>
                                            : null}
                                        {!isBookSlot ?
                                            <li className="nav-item butn seo">
                                                <Link className="nav-link" to={`tel:${customCallus ? customCallus : isPaidPage ? '+919355023577' : '+919355618123'}`}>Call: {customCallus ? customCallus : isPaidPage ? '93550 23577' : '93556 18123'}</Link>
                                            </li>
                                            : null} */}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Header