import React from 'react'
import { Helmet } from 'react-helmet';
import Header from '../Header'
import Footer from '../Footer';
import "./style.css"
import ContactCard from "./contactCard"


function PatientCare() {
  return (
    <div className="contact_us_header">
      <h1 className="contact_us_header-title">
        Contact us<br />
      </h1>
      <p className="contact_us_header-description">
        Thank you for reaching out to us! We're here to assist you.<br />
        If you have any question or comment about Kyno Health,
        <br />please reach out to us and we will address it as quickly and efficiently as possible.
      </p>
      <a href="tel:9953104104">
        <div className="contact_us_header-availability">
          Call at 9953104104
          {/* Available in <span className="cities">Delhi NCR & Bangalore</span> */}
        </div>
      </a>
    </div>
  );
}

function index() {
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us : Bringing Healthcare to Your Doorstep</title>
        <meta name="description"
          content="Access quality healthcare with home visit doctors near you. Convenient and personalized medical care in the comfort of your home. Schedule an appointment today." />
        <link rel="canonical" href="https://www.kynohealth.com/doctors-near-me/" />
      </Helmet>
      <Header />

      <PatientCare />
      <ContactCard />
      <div className="timing-section">
        {/* <div>
          <h3 className='timing_title'>Business Hours</h3>
          <div className="sub_text">Monday - Sunday<br />9AM - 6PM</div>
        </div> */}
        
        <div className='timing_card_section'>
          <div className="timing-cards">
            <div className="info_card">
              <div className="info_label">Email</div>
              <div className="sub_text"><a href='mailto:support@kynohealth.com'>support@kynohealth.com</a></div>
            </div>
            <div className="info_card">
              <div className="info_label">Phone</div>
              <div className="sub_text"><a href='tel:9953104104'>9953104104</a></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default index
