import React from "react";
import logo from "../../lendingPage/Header/images/kyno.svg";
import { Link } from "react-router-dom";
import Image from "react-image-webp";
import "./GetMobileNumber.css";

export default function HeaderLogo() {
  return (
    <Link
      className="header-logo"
      to={`/?source=${window.location.pathname.split("/").join("")}`}
    >
      <Image
        src={logo}
        webp={logo}
        title="Kyno Health Logo"
        alt="headLogoImg"
      />
    </Link>
  );
}
