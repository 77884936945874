import React, { useCallback, useEffect, useState } from "react";

export default function SearchInput({
  setCrds = () => {},
  styles = {},
  fetchLocationFromLatLong = () => {},
  additionalSearch = "",
}) {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const fetchSuggestions = useCallback(async (value) => {
    if (value.length > 0) {
      try {
        const response = await fetch(
          `https://kynohealth.com/ssr-api/location?input=${value}&withPlaceIds=true`
        );
        const data = await response.json();
        setSuggestions(data);
        setShowDropdown(true);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setShowDropdown(false);
        setSuggestions([]);
      }
    } else {
      setShowDropdown(false);
      setSuggestions([]);
    }
  }, []);
  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 300),
    []
  );

  // debounce.ts
  function debounce(func, delay) {
    let timeoutId;

    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  useEffect(() => {
    if (additionalSearch) {
      debouncedFetchSuggestions(additionalSearch);
    }
  }, [additionalSearch]);

  const fetchLatLong = async (placeId) => {
    try {
      const response = await fetch(
        `https://kynohealth.com/ssr-api/location?placeId=${placeId}`
      );
      const data = await response.json();
      const { lat, lng } = data;
      fetchLocationFromLatLong({
        coords: { latitude: lat, longitude: lng },
      });
      //   setLatLong(`${lat}, ${lng}`);
      setShowDropdown(false);
      setCrds({ lat, lng });
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          debouncedFetchSuggestions(e.target.value);
          setShowDropdown(true);
        }}
        placeholder="Search location"
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "10px",
          border: "1px solid #169d8280",
          boxShadow: "rgba(51, 51, 51, 0.6) 1px 1px 14px",
          ...(styles && styles),
        }}
      />
      {showDropdown && query && suggestions.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            zIndex: 1001,
          }}
        >
          {suggestions.map((place, index) => (
            <div
              key={index}
              onClick={() => {
                setQuery(place.description);
                fetchLatLong(place.placeId);
              }}
              style={{
                padding: "10px",
                cursor: "pointer",
                fontSize: "12px",
                borderBottom:
                  index < suggestions.length - 1 ? "1px solid #eee" : "none",
                hover: {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              {place.description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
