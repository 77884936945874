import React from "react";
import Nav from "../Nav/index";

import "./css/style.css";

// import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Header from "../../lendingPage/Header";
import Footer from "../../lendingPage/Footer";

function index() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"
        ></script>

        <title>Blog - Kyno Health</title>
        <meta
          name="description"
          content="Learn about common vector borne diseases, their symptoms, prevention tips, and treatment options. Stay informed and protect your health with expert insights from Kyno Health."
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://www.kynohealth.com/blog" />
      </Helmet>

      <Header />

      <section className="blog ms">
        <div className="container">
          <div className="row">

          <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                <h5 className="card-title">Acidity Home Remedies: Fast &amp; Natural Relief</h5>
                  <p className="card-text">Craving your favorite meal but suffering from acidity afterward? </p>
                  <a
                    href="/blog/acidity-home-remedies-fast-natural-relief"
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                <h5 className="card-title">Hyper Acidity Home Remedies: Powerful Fixes for Immediate Relief</h5>
                  <p className="card-text">The Hydrochloric Acid in our body, secreted by the stomach </p>
                  <a
                    href="/blog/hyper-acidity-home-remedies-immediate-relief"
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                <h5 className="card-title">Fast-Acting Home Remedies for Heartburn and Acid Reflux</h5>
                  <p className="card-text">We&rsquo;ve all had that moment&mdash;savoring a delicious meal only to be faced</p>
                  <a
                    href="/blog/home-remedies-heartburn-acid-reflux-relief"
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                <h5 className="card-title">Common Acidity Symptoms and How to Spot Them Early</h5>
                  <p className="card-text">Acidity or acid reflux is a condition characterized by the flowing of stomach acid</p>
                  <a
                    href="/blog/acidity-symptoms-early-warning-signs"
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            {/* ----------------------- */}

          <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                  Sore Throat? Try These Natural Home Remedies for Quick Relief
                  </h5>
                  <p className="card-text">
                  A sore throat can be a frustrating symptom, often signaling the onset..
                  </p>
                  <a
                    href="/blog/sore-throat-natural-home-remedies"
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                  Quick Relief: Top 7 Remedies to Ease Throat Ache at Home
                  </h5>
                  <p className="card-text">
                  Throat aches can be an annoying and uncomfortable experience...
                  </p>
                  <a
                    href="/blog/top-7-remedies-ease-throat-ache-at-home"
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                  Ultimate Guide to Healing Throat Infections Naturally at Home
                  </h5>
                  <p className="card-text">
                  Throat infections are ordinary diseases that can make daily...
                  </p>
                  <a
                    href="/blog/guide-healing-throat-infections-naturally"
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                  Say Goodbye to Throat Pain: Effective Home Remedies You Can Trust
                  </h5>
                  <p className="card-text">
                  Throat pain can be more than just a minor inconvenience...
                  </p>
                  <a
                    href="/blog/goodbye-to-throat-pain-home-remedies"
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Benefits of Regular Doctor Visits: Investing in Your
                    Well-Being
                  </h5>
                  <p className="card-text">
                    In the fast-paced world we live in, maintaining good health
                    often takes a back..
                  </p>
                  <a
                    href="/blog/benefits-of-regular-doctor-visits "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />{" "}
                  <h5 className="card-title">
                    The Best Dandruff Fighting Routine: Daily, Weekly, and
                    Monthly Tips
                  </h5>
                  <p className="card-text">
                    A healthy dandruff-free scalp with good hair quality is the
                    result...{" "}
                  </p>
                  <a
                    href="/blog/best-dandruff-fighting-routine "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />{" "}
                  <h5 className="card-title">
                    Breastfeeding: Nature's Perfect Gift for Mom and Baby
                  </h5>
                  <p className="card-text">
                    Mothers and their newborns receive the most wonderful gift
                    from nature...
                  </p>
                  <a
                    href="/blog/breastfeeding-perfect-gift-mom-baby "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />{" "}
                  <h5 className="card-title">Caring For The Elderly At Home</h5>
                  <p className="card-text">
                    As our loved ones age, their healthcare needs evolve, often
                    becoming more complex...
                  </p>
                  <a
                    href="/blog/caring-for-the-elderly-at-home "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Understanding the Causes of Dandruff and Itchy Scalp: A
                    Holistic View
                  </h5>
                  <p className="card-text">
                    Often, people get confused about dandruff and flaky scalp,
                    assuming both are the same...
                  </p>
                  <a
                    href="/blog/causes-of-dandruff-and-itchy-scalp "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Comprehensive Guide to a General Health Check-Up: Tests and
                    Procedures
                  </h5>
                  <p className="card-text">
                    Maintaining good health requires regular monitoring and
                    proactive care, which...
                  </p>
                  <a
                    href="/blog/comprehensive-guide-general-health-checkup "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Benefits of Choosing Doctor at Home Services for Noida
                    Residents
                  </h5>
                  <p className="card-text">
                    In the lively city of Noida, life moves quickly. This is why
                    having access to good healthcare...
                  </p>
                  <a
                    href="/blog/doctor-at-home-services-noida "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    How to Find Reliable Home Visit Doctors in Your Area
                  </h5>
                  <p className="card-text">
                    In times of illness, accidents, or mobility issues, ease and
                    accessibility are key elements...
                  </p>
                  <a
                    href="/blog/find-reliable-home-visit-doctors-guide "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Fueling Your Workday- How Balanced Meals Boost Productivity!
                  </h5>
                  <p className="card-text">
                    Have you ever been in a situation where your colleagues had
                    to wake you up from snoozing...
                  </p>
                  <a
                    href="/blog/fueling-your-workday "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Heatwave Alert!! 5 Expert Tips to Beat the Heat
                  </h5>
                  <p className="card-text">
                    As a tormenting heatwave engulfs the entire nation, it feels
                    like we're all being slow-cooked...
                  </p>
                  <a
                    href="/blog/heatwave-alert-5-tips-to-beat-the-heat "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Herbal Remedies for Cough: Natural Solutions for Quick
                    Relief
                  </h5>
                  <p className="card-text">
                    Are you having coughing fits that just don't go away? Well,
                    you don't have to worry...
                  </p>
                  <a
                    href="/blog/herbal-remedies-cough "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Home Remedies for Dry Cough: Tried and Tested Solutions
                  </h5>
                  <p className="card-text">
                    Dry coughs are a real inconvenience. They can disrupt your
                    whole day and even keep...
                  </p>
                  <a
                    href="/blog/home-remedies-dry-cough "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Mastering the Art of Blood Pressure Control: A Comprehensive
                    Guide
                  </h5>
                  <p className="card-text">
                    Maintaining optimal blood pressure is vital for overall
                    health and well-being...
                  </p>
                  <a
                    href="/blog/how-to-control-blood-pressure "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    The Importance of Scheduling and Attending Doctor
                    Appointments
                  </h5>
                  <p className="card-text">
                    In our busy lives, filled with work commitments, social
                    obligations, and personal responsibilities...
                  </p>
                  <a
                    href="/blog/importance-of-doctor-appointments "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    IV Therapy at Home: What You Need to Know About Safety and
                    Benefits
                  </h5>
                  <p className="card-text">
                    IV therapy at home is an increasingly popular option for
                    those seeking convenience and personalized care...
                  </p>
                  <a
                    href="/blog/iv-therapy-at-home-safety-benefits "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Mastering Monsoon Health- Expert Tips to Avoid Getting Sick
                  </h5>
                  <p className="card-text">
                    The tormenting heatwave in India is all set to depart,
                    leaving everyone super excited about monsoons!...
                  </p>
                  <a
                    href="/blog/mastering-monsoon-health-expert-tips "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Navigate BP Issues The Right Way
                  </h5>
                  <p className="card-text">
                    The Science Behind Why You Need To Monitor Your Blood
                    Pressure...
                  </p>
                  <a
                    href="/blog/navigate-bp-issues-monitor-blood-pressure "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Optimise Your Post-Surgery Recovery- Tips by Kyno Health
                    Experts
                  </h5>
                  <p className="card-text">
                    Have you recently had surgery or are scheduled for one?
                    While you may have received ample...
                  </p>
                  <a
                    href="/blog/post-surgery-recovery-tips "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Prescription Medications for Dry Cough: What You Need to
                    Know
                  </h5>
                  <p className="card-text">
                    Several over-the-counter medicines can help relieve a dry
                    cough. But it is best if you visit a doctor...
                  </p>
                  <a
                    href="/blog/prescription-medications-dry-cough "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    The Role of General Physicians in Your Healthcare Journey
                  </h5>
                  <p className="card-text">
                    When it comes to managing your health, a crucial player in
                    the healthcare landscape is the...
                  </p>
                  <a
                    href="/blog/roles-of-a-general-physician "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">SPF In Monsoon</h5>
                  <p className="card-text">
                    Cloudy skies, pouring rain, and pleasant winds, what a
                    lovely season! Monsoons seem like nature's...
                  </p>
                  <a
                    href="/blog/spf-in-monsoon "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Stay Safe this Summer: Avoid Fake ORS, Choose WHO-Approved
                    Hydration
                  </h5>
                  <p className="card-text">
                    As the weather gets hotter and summer sets in, it's natural
                    to feel the heat more than ever...
                  </p>
                  <a
                    href="/blog/stay-safe-this-summer-choose-who-approved-ors "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Dos and Don'ts for Supporting Someone During Seizures
                  </h5>
                  <p className="card-text">
                    There could be a sudden, unforeseen instance when someone
                    you care about experiences a seizure...
                  </p>
                  <a
                    href="/blog/support-during-seizures "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Health Info Overload? How to Tackle 'IDIOT' Syndrome
                  </h5>
                  <p className="card-text">
                    Do you also trust Dr. Web more than real doctors? You may
                    have the infamous ‘IDIOT’ Syndrome...
                  </p>
                  <a
                    href="/blog/tackle-idiot-syndrome "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Tips For Finding The Best Home Visit Doctors
                  </h5>
                  <p className="card-text">
                    Are you struggling to find potential home visit doctors near
                    me on the web to manage your ...
                  </p>
                  <a
                    href="/blog/tips-finding-best-home-visit-doctors "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Navigating High Fever: Practical Tips for Relief and
                    Recovery
                  </h5>
                  <p className="card-text">
                    A high fever can be a concerning and uncomfortable
                    experience, often signaling an underlying
                  </p>
                  <a
                    href="/blog/tips-to-relief-from-high-fever "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Top 10 Home Remedies for Effective Dandruff Treatment
                  </h5>
                  <p className="card-text">
                    Tired of managing your everlasting dandruff? Time to rely on
                    the best home remedies! ...
                  </p>
                  <a
                    href="/blog/top-10-home-remedies-for-dandruff-treatment "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Top Home Remedies for Dandruff: Natural Ways to Restore
                    Scalp Health
                  </h5>
                  <p className="card-text">
                    Are you suffering from dandruff? This hair fungus makes the
                    scalp excessively dry and flaky...
                  </p>
                  <a
                    href="/blog/top-home-remedies-for-dandruff "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Understanding Dry Cough: Causes, Symptoms, and Treatment
                    Options
                  </h5>
                  <p className="card-text">
                    A dry cough can be a bothersome illness. It causes
                    discomfort and makes daily life feel sluggish...
                  </p>
                  <a
                    href="/blog/understanding-dry-cough "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Understanding Fever: When to See a General Physician or a
                    Specialist
                  </h5>
                  <p className="card-text">
                    The increase in the body temperature from 98.6°F, which is
                    considered the normal body temperature...
                  </p>
                  <a
                    href="/blog/understanding-fever-when-to-consult "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">VECTOR BORNE DISEASES...</h5>
                  <p className="card-text">
                    We all enjoy the tropical climate of India—the warmth of the
                    sun, the beauty of nature...
                  </p>
                  <a
                    href="/blog/vector-borne-diseases "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    What is Diabetes? (+ Types, Causes, Symptoms & Treatment)
                  </h5>
                  <p className="card-text">
                    Diabetes is a prevalent and chronic medical condition that
                    affects millions of people worldwide...
                  </p>
                  <a
                    href="/blog/what-is-diabetes "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Why Every Family Needs a General Physician: The Basics
                    Explained
                  </h5>
                  <p className="card-text">
                    A general physician plays a crucial role in ensuring the
                    overall health and well-being of every family...
                  </p>
                  <a
                    href="/blog/why-every-family-needs-general-physician "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="/images/excellence.svg"
                    className="img-fluid"
                    alt="bnrImg"
                  />
                  <h5 className="card-title">
                    Top 10 Benefits of Home Visit Doctors
                  </h5>
                  <p className="card-text">
                    With the revolution of the medical industry, the role of
                    Home Visit Doctors has significantly...
                  </p>
                  <a
                    href="/blog/top-10-benefits-home-visit-doctors "
                    className="btn btn-outline-success"
                  >
                    Read more
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default index;
