import React from "react";

export default function DetectIcon({ size = "24" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41504 11.9998C8.41504 12.9506 8.79274 13.8624 9.46506 14.5348C10.1374 15.2071 11.0492 15.5848 12 15.5848C12.9508 15.5848 13.8627 15.2071 14.535 14.5348C15.2073 13.8624 15.585 12.9506 15.585 11.9998C15.375 7.24479 8.62504 7.24104 8.41504 11.9998ZM12 14.0848C9.24379 13.9985 9.24379 10.001 12 9.91479C14.7563 10.001 14.7563 13.9985 12 14.0848Z"
        fill="#179D82"
      />
      <path
        d="M22.5 11.25H20.19C19.68 6.60375 16.3088 4.125 12.75 3.825V1.5C12.75 1.30109 12.671 1.11032 12.5303 0.96967C12.3897 0.829018 12.1989 0.75 12 0.75C11.8011 0.75 11.6103 0.829018 11.4697 0.96967C11.329 1.11032 11.25 1.30109 11.25 1.5V3.825C7.69125 4.125 4.32 6.60375 3.81 11.25H1.5C1.30109 11.25 1.11032 11.329 0.96967 11.4697C0.829018 11.6103 0.75 11.8011 0.75 12C0.75 12.1989 0.829018 12.3897 0.96967 12.5303C1.11032 12.671 1.30109 12.75 1.5 12.75H3.78375C3.95903 14.6711 4.80195 16.47 6.16599 17.834C7.53003 19.198 9.32894 20.041 11.25 20.2162V22.5C11.25 22.6989 11.329 22.8897 11.4697 23.0303C11.6103 23.171 11.8011 23.25 12 23.25C12.1989 23.25 12.3897 23.171 12.5303 23.0303C12.671 22.8897 12.75 22.6989 12.75 22.5V20.2162C14.6711 20.041 16.47 19.198 17.834 17.834C19.198 16.47 20.041 14.6711 20.2162 12.75H22.5C22.6989 12.75 22.8897 12.671 23.0303 12.5303C23.171 12.3897 23.25 12.1989 23.25 12C23.25 11.8011 23.171 11.6103 23.0303 11.4697C22.8897 11.329 22.6989 11.25 22.5 11.25ZM5.25 12C5.25 10.2098 5.96116 8.4929 7.22703 7.22703C8.4929 5.96116 10.2098 5.25 12 5.25C13.7902 5.25 15.5071 5.96116 16.773 7.22703C18.0388 8.4929 18.75 10.2098 18.75 12C18.375 20.9513 5.625 20.955 5.25 12Z"
        fill="#179D82"
      />
    </svg>
  );
}
