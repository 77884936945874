import React from "react";
import "./index.css"; // Import the CSS file for styling

const BrandButton = ({ label, onClick, disabled, className, style }) => {
  return (
    <button
      className={`brand-button ${disabled ? "disabled" : ""} ${className || ""}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {label}
    </button>
  );
};

export default BrandButton;