import React from "react";
import "./PersonalInfoForm.css";

export default function Separator() {
  return (
    <div className="separator">
      <div className="separator-line"></div>
      <div>OR</div>
      <div className="separator-line"></div>
    </div>
  );
}
