import { Navigate } from "react-router-dom";
import { useAuth } from "../context/Authenticate/AuthContext";

const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  console.log({ auth })
  return auth?.isAuthenticated ? children : <Navigate to="/booking-v3" replace />;
};

export default PrivateRoute;

