import React, { useEffect, useRef, useState } from "react";
import BrandButton from "../BrandButton"; // Reuse the BrandButton component
import "./PersonalInfoForm.css"; // Import the CSS file for styling
import HeaderLogo from "../LoginScreen/HeaderLogo";
import SpecialistBanner from "./SpecialistBanner";
import PriceBAnner from "./PriceBAnner";
import Steps from "./Steps";
import HappyFamily from "../LoginScreen/HappyFamily";
import Separator from "./Separator";
import DetectIcon from "../../Icons/DetectIcon";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MenuItem, Select } from "@mui/material";
import OverlayLoader from "../../spinner";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import SearchInput from "./SearchInput";
import ETAFile from "../BookingPage/ETA";
import MapComponent from "./MapComponent";

function getCurrentTimeFormatted() {
  const now = new Date();

  // India Standard Time (IST) is UTC+05:30
  const IST_OFFSET = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
  const istNow = new Date(now.getTime() + IST_OFFSET);

  const pad = (num, size = 2) => String(num).padStart(size, '0');

  const year = istNow.getUTCFullYear();
  const month = pad(istNow.getUTCMonth() + 1);
  const date = pad(istNow.getUTCDate());
  const hours = pad(istNow.getUTCHours());
  const minutes = pad(istNow.getUTCMinutes());
  const seconds = pad(istNow.getUTCSeconds());
  const milliseconds = pad(istNow.getUTCMilliseconds(), 3);

  return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.000+05:30`;
}

const handleSwtichToCity = (addresss) => {
  let city = null;
  if (addresss.toLowerCase().includes("delhi")) {
    city = "Delhi";
  } else if (addresss.toLowerCase().includes("noida")) {
    city = "Noida";
  } else if (addresss.toLowerCase().includes("bangalore")) {
    city = "Bengaluru";
  } else if (addresss.toLowerCase().includes("bengaluru")) {
    city = "Bengaluru";
  } else if (addresss.toLowerCase().includes("ghaziabad")) {
    city = "Ghaziabad";
  } else if (addresss.toLowerCase().includes("gurugram")) {
    city = "Gurugram";
  } else if (addresss.toLowerCase().includes("faridabad")) {
    city = "Faridabad";
  } else if (addresss.toLowerCase().includes("gurgaon")) {
    city = "Gurgaon";
  }
  // const city = address.split(",")[1].trim()
  return city;
};

const center = {
  lat: 37.7749, // Initial lat
  lng: -122.4194, // Initial lng
};

function calculateDOB(age) {
  const today = new Date();
  const birthYear = today.getFullYear() - age;
  const dob = new Date(birthYear, today.getMonth(), today.getDate());

  const day = String(dob.getDate()).padStart(2, "0");
  const month = String(dob.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = dob.getFullYear();

  return `${day}-${month}-${year}`;
}

const PersonalInfoForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let edit = searchParams.get("edit");
  const [step, setStep] = useState(edit ? Number(edit) : localStorage.getItem("selected_user_id") ? 2 : 1); // Step 1: Personal Info, Step 2: Address
  const [existingUsers, setExistingUsers] = useState([]);
  const [existinglocations, setExistingLocations] = useState([]);
  const navigate = useNavigate();
  const [markerPosition, setMarkerPosition] = useState(false);
  const [selectedExisting, setSelectedExisting] = useState(Number(localStorage.getItem("user_id")) || "");
  // In your existing state declarations
  const [checkExisting, setCheckExisting] = useState(false);
  const [checkExitingAddress, setCheckExistingAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(Number(localStorage.getItem("address_id")) || "");
  const [isAddressChanged, setIsAddressChanged] = useState(false);
  const [crds, setCrds] = useState({ lat: "", lng: "" });
  const [appointment, setAppointment] = useState(null);
  // Personal Information State
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [healthConcerns, setHealthConcerns] = useState("");

  const [bookingErr, setBookingErr] = useState("");
  // Address State
  const [houseNo, setHouseNo] = useState("");
  const [apartment, setApartment] = useState(localStorage.getItem("apartment") || "");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  // const [postalCode, setPostalCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddressLabel, toggleAddressLabel] = useState(false);
  const [latlong, setLatLong] = useState("");

  const [heavyModal, setHeavyModal] = useState(false);

  const [map, setMap] = useState(null);
  const mapTRef = useRef(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isEditable, setIseditable] = useState(false);

  const [mapAddressChange, setMapAddressChange] = useState(false);
  const [APILoader, setAPILoader] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
  });

  // const [country, setCountry] = useState("");
  // const [state, setState] = useState("");

  // Error State
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const userId =
      localStorage.getItem("user_id") || sessionStorage.getItem("user_id");

    if (!userId) {
      navigate("/book-doctor/step-1");
    }
  }, [navigate]);

  useEffect(() => {

    const token = localStorage.getItem("token");
    let userId = localStorage.getItem("user_id") || sessionStorage.getItem("user_id")
    if (!token || !userId) {
      localStorage.clear()
      navigate("/book-doctor/step-1");
    } else if (!edit) {
      let savedAppointment = localStorage.getItem("savedAppointment")
      if (savedAppointment) {
        savedAppointment = JSON.parse(savedAppointment)
        navigate(`/book-doctor/step-3?appId=${savedAppointment.id}`)
      }
    }
    setCheckExisting(true);
    if (selectedExisting) {
      handleSetPerDetails()
    }
    if (selectedAddress) {
      handleSetLocationDetails()
    }
    // }
  }, []);

  // Validate Personal Information Form
  const validatePersonalInfo = () => {
    const newErrors = {};

    if (!firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    // if (!lastName.trim()) {
    //   newErrors.lastName = "Last Name is required";
    // }
    if (!age.trim()) {
      newErrors.age = "Age is required";
    } else if (isNaN(age) || age <= 0) {
      newErrors.age = "Age must be a valid number";
    } else if (age < 14 || age > 120) {
      newErrors.age = "Age must be between 14 and 120";
    }

    if (!gender.trim()) {
      newErrors.gender = "Gender is required";
    }
    if (!healthConcerns.trim()) {
      newErrors.healthConcerns = "Health Concerns are required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const validateAddress = () => {
    const newErrors = {};

    // if (!area.trim()) {
    //   newErrors.area = "Area is required";
    // }

    if (!apartment.trim()) {
      newErrors.apartment = "Apartment is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleUpdateUserProfile = async () => {
    const myHeaders = new Headers();

    myHeaders.append("token", localStorage.getItem("token"));
    // myHeaders.append("Content-Type", "multipart/form-data");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      data: {
        attributes: {
          id: selectedExisting || localStorage?.getItem("user_id"),
          first_name: firstName,
          gender:
            gender.slice(0, 1).toUpperCase() + gender.slice(1).toLowerCase(),
          last_name: lastName,
          date_of_birth: calculateDOB(age),
        },
      },
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // handleError(otpResponseApi)
    try {
      let response = await fetch(
        process.env.REACT_APP_KMS_BASE_URL +
          "/api/account/profiles/update_profile",
        requestOptions
      );

      let result = await response.json();

      console.log({ result });

      if (response.ok) {
        // setStep(2);
        localStorage.setItem("selected_user_id", result.id);
        return result;
      }
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const createAppointment = async () => {
    const formData = new FormData();
    const myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem("token"));
    formData.append("patient_id", localStorage.getItem("user_id"));
    formData.append("latitude", crds?.lat || "");
    formData.append("longitude", crds.lng || "");
    formData.append("source", "website");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_KMS_BASE_URL + "/api/customers/express_book",
        requestOptions
      );

      const result = await response.text();
      const res = JSON.parse(result);

      console.log("express", { result }, res);

      if (!res.appointment_id) {
        setAppointment(null);
        setAPILoader(false);
        setHeavyModal(true);
        setBookingErr(res?.errors);
        toast.error(
          res?.errors ||
            "Appointment can't be booked now, please contact support."
        );
        return;
      }

      // Update the appointment with health concerns
      await updateAppointmentWithHealthConcerns(res.appointment_id);

      // Continue with the rest of the function
      let appointmentDetails = await fetch(
        process.env.REACT_APP_KMS_BASE_URL +
          `/api/customers/appointments/${res.appointment_id}`,
        {
          headers: myHeaders,
        }
      );

      appointmentDetails = await appointmentDetails.text();
      appointmentDetails = JSON.parse(appointmentDetails);

      if (appointmentDetails.appointment_start_time) {
        setAppointment(appointmentDetails);
        localStorage.setItem("savedAppointment", JSON.stringify({
          ...appointmentDetails,
          // appointment_start_time: null,
          // created_at: getCurrentTimeFormatted()
        }))
        navigate(`/book-doctor/step-3?appId=${res.appointment_id}`);
      } else {
        setAppointment(null);
      }

      if (res.razorpay_order_id && res.appointment_id) {
        toast.success("Appointment Booked!");
      } else {
        toast.error(
          res?.errors ||
            "Appointment can't be booked now, please contact support."
        );
      }
    } catch (error) {
      toast.error(
        error?.errors ||
          "Appointment can't be booked now, please contact support."
      );
      console.log("error", error);
    }
  };

  const extractAreaData = (response) => {
    const street = [];
    const route = [];
    const subLocalities = [];
    const localities = [];
    const administrativeAreas = [];

    response.forEach((component) => {
      const { long_name, types } = component;

      if (types.includes("street_number")) {
        street.push(long_name);
      } else if (types.includes("route")) {
        route.push(long_name);
      } else if (types.includes("sublocality")) {
        subLocalities.push(long_name);
      } else if (types.includes("locality")) {
        localities.push(long_name);
      } else if (types.includes("administrative_area_level_3")) {
        administrativeAreas.push(long_name);
      }
    });

    const combinedArea = [
      ...subLocalities,
      ...localities,
      ...administrativeAreas,
    ].join(", ");

    return combinedArea;
  };

  const getExistingLocations = async (addressable_id) => {
    try {
      const res = await fetch(
        process.env.REACT_APP_KMS_BASE_URL +
          `/api/customers/addresses/list_customer_addresses?addressable_id=${addressable_id}`,
        {
          method: "GET",
          headers: { token: localStorage.getItem("token") },
        }
      );

      const result = await res.json();

      if (res.ok) {
        setExistingLocations(result);
        return;
      }

      console.log({ result });

      if (result.error) {
        console.log(result.error);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getOwnerDetails = async (addressable_id) => {
    try {
      const res = await fetch(
        process.env.REACT_APP_KMS_BASE_URL +
          `/api/customers/customer_info`,
        {
          method: "GET",
          headers: { token: localStorage.getItem("token") },
        }
      );

      const result = await res.json();

      if (res.ok) {
        handleSelectExisting(result)
        return;
      }

      console.log({ result });

      if (result.error) {
        console.log(result.error);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const createPatient = async () => {
    const formData = new FormData();

    // Add form fields to match the cURL structure
    formData.append("patient[first_name]", firstName);
    formData.append("patient[last_name]", lastName);
    formData.append(
      "patient[gender]",
      gender === "Other" ? "Transgender" : gender
    );
    formData.append("patient[mobile_number]", localStorage.getItem("phone"));
    formData.append("patient[date_of_birth]", calculateDOB(age));

    const response = await fetch(
      process.env.REACT_APP_KMS_BASE_URL + "/api/customers/patients",
      {
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        body: formData,
      }
    );

    const result = await response.json();
    console.log(result);

    // console.log(currentPatient, ">??? curr");

    console.log({ result });

    if (response.ok) {
      // console.log(result.id, "id", result.first_name);
      localStorage.setItem("user_id", result.id); // cleaner
      localStorage.setItem("selected_user_id", result.id);
      return result;
    }
  };

  const saveHealthConcernsToSession = () => {
    if (healthConcerns.trim()) {
      localStorage.setItem("healthConcerns", healthConcerns);
    }
  };

  const updateAppointmentWithHealthConcerns = async (appointmentId) => {
    const healthConcernsValue = localStorage.getItem("healthConcerns") || "";

    const myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem("token"));

    const formData = new FormData();
    formData.append("appointment_complains_attributes[0][complain_id]", "95"); // Using 95 as the complain_id from your example
    formData.append(
      "appointment_complains_attributes[0][description]",
      healthConcernsValue
    );

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_KMS_BASE_URL}/api/customers/appointments/${appointmentId}`,
        requestOptions
      );

      const result = await response.json();

      console.log("Health concerns updated for appointment:", result);
      return result;
    } catch (error) {
      console.error("Error updating appointment with health concerns:", error);
      return null;
    }
  };

  // Handle Continue Button Click
  const handleContinue = async () => {
    localStorage.setItem("current_patient", firstName + " " + lastName);
    localStorage.setItem("age", age);
    localStorage.setItem("gender", gender);
    localStorage.removeItem("savedAppointment")

    if (step === 1) {
      let patient = {
        firstName,
        lastName: lastName || "",
        age,
        id: selectedExisting || null,
        description: healthConcerns || "",
        gender
      }
      localStorage.setItem("patient", patient);
      if (validatePersonalInfo()) {
        // Add this line to save health concerns
        saveHealthConcernsToSession();

        const newUSerwithoutList = existingUsers?.length === 0;
        if (checkExisting || newUSerwithoutList) {
          handleUpdateUserProfile();
        } else {
          await createPatient();
        }
        localStorage.removeItem("apartment")
        setApartment("")
        setSelectedAddress("")
        setCheckExistingAddress(false)
        
        setStep(2); // Move to Step 2 (Address Form)
        // setSearchParams({ edit: 2})
      }
    } else if (step === 2) {
      if (mapAddressChange) {
        setIsMapOpen(true);

        return;
      }

      setAPILoader(true);
      if (validateAddress()) {
        if (selectedAddress) {
          await makeAddressActive();
        } else {
          addPatientAddress();
        }
        // alert("Form submitted!"); // Handle form submission
      }
    }
  };
  const getLocation = () => {
    setIsAddressChanged(false);
    setIsMapOpen(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        fetchLocationFromLatLong,
        showError
      );
    } else {
      setErrorMessage("Geolocation is not supported by this browser.");
    }
  };
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // setErrorMessage('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        setErrorMessage("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        setErrorMessage("The request to get user location timed out.");
        break;
      default:
        setErrorMessage("An unknown error occurred.");
        break;
    }
  };

  const fetchLocationFromLatLong = async (position) => {
    setIsAddressChanged(false);
    const { latitude, longitude } = position.coords;
    setLatLong(`${latitude}, ${longitude}`);
    setCrds({ lat: latitude, lng: longitude });
    // const apiKey = process.env.NEXT_PUBLIC_MAP_KEY;
    // const url = fetch(`/ssr-api/location?latitude=${latitude}&longitude=${longitude}`);

    try {
      const response = await fetch(
        `https://kynohealth.com/ssr-api/location?latitude=${latitude}&longitude=${longitude}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const userLocation = data.results[0].formatted_address;

        try {
          // const list = data?.results[0].address_components.find((e) =>
          //   e.types.includes("postal_code")
          // );

          // if (list) {
          //   setPostalCode(list.long_name);
          // }

          const city = data?.results[0].address_components.find((e) =>
            e.types.includes("locality")
          );
          if (city) {
            setCity(city.long_name);
          }

          const floor = data?.results[0].address_components.find((e) =>
            e.types.includes("subpremise")
          );

          if (floor) {
            setHouseNo(floor.long_name);
          }
          const areaValue = extractAreaData(data.results[0].address_components);

          if (areaValue) {
            setArea(areaValue);
          }

          const housevalue = data?.results[0].address_components.find((e) => {
            return e.types.includes("premise");
          });

          if (housevalue) {
            setApartment(housevalue.long_name);
          }
        } catch (error) {}

        setQuery(userLocation);
        // setSelectedLocation(userLocation);
        toggleAddressLabel(true);
        //   setLocationInput(userLocation)
        setShowDropdown(false);
      } else {
        setErrorMessage("Unable to fetch location details.");
      }
    } catch (err) {
      setErrorMessage("Failed to fetch location data from Google Maps.");
    }
  };

  const addPatientAddress = async () => {
    const myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    const cityValue = city || handleSwtichToCity(query);
    const state = city || handleSwtichToCity(query);

    if (!city || !state) {
      console.log("service not available in this city/state");
    }

    const raw = JSON.stringify({
      floor_number: houseNo,
      addressable_id: String(localStorage.getItem("user_id")),
      apartment: apartment,
      address: query,
      // street: area,
      city: cityValue,
      state: state,
      // country: area.split(",")[area.split(",").length - 1]?.trim(),
      // zipcode: postalCode,
      lat: crds.lat,
      lng: crds.lng,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_KMS_BASE_URL + "/api/customers/addresses",
        requestOptions
      );

      let result = await response.json();

      console.log({ result });

      if (response.ok) {
        // result = JSON.parse(result)
        localStorage.setItem("address_id", result.id)
        localStorage.setItem("apartment", apartment)
        await createAppointment();
        setStep(3);
        return result;
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      setAPILoader(false);
    }
  };

  const makeAddressActive = async (addressId, addressableId) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("token", localStorage.getItem("token"));
      myHeaders.append("Content-Type", "application/json");
      localStorage.setItem("address_id", selectedAddress)
      localStorage.setItem("apartment", apartment)
      const raw = JSON.stringify({
        address_id: selectedAddress,
        addressable_id: localStorage.getItem("user_id"),
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.REACT_APP_KMS_BASE_URL +
          "/api/customers/addresses/mark_customer_address_as_active",
        requestOptions
      );

      const result = await response.json();
      console.log({ result });

      if (response.ok) {
        await createAppointment();
        setStep(3);
        return result;
      } else {
        console.error("Failed to mark address as active:", result);
        setAPILoader(false);
        toast.error("Appointment can't be booked now, please contact support.");
        return null;
      }
    } catch (error) {
      console.error("Error marking address as active:", error);
      return null;
    }
  };
  const getExistingUsers = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_KMS_BASE_URL +
          `/api/customers/patients/previous_appointment_patients`,
        { method: "GET", headers: { token: localStorage.getItem("token") } }
      );

      const result = await response.json();
      // console.log(result, "whats the result?");

      console.log({ result });
      if (response.ok) {
        setExistingUsers(result);
        if (!result?.length) {
          getOwnerDetails()
        }
        // setTimeout(handleSetPerDetails())
        // handleSetPerDetails()
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSetPerDetails = async () => {
    await getExistingUsers()
    // setTimeout(() => {
    //   console.log({existingUsers})
    //   existingUsers?.forEach(item => {
    //     if (item.id === selectedExisting) {
    //       handleSelectExisting(item)
    //     }
    //   }, 1000)
      
    // })
  }

  useEffect(() => {
    existingUsers?.forEach(item => {
      if (item.id === selectedExisting) {
        handleSelectExisting(item)
      }
    })
  }, [existingUsers])

  useEffect(() => {
    existinglocations?.forEach(item => {
      if (item.id === selectedAddress) {
        handleSelectAddress(item)
      }
    })
  }, [existinglocations])

  const handleSetLocationDetails = async () => {
    await getExistingLocations(localStorage.getItem("user_id"))
      // existinglocations?.forEach(item => {
      //   if (item.id === selectedAddress) {
      //     handleSelectAddress(item)
      //   }
      // })
  }

  const handleMarkerDragEnd = (e) => {
    const clickedLatLng = e.latLng;

    // Use the map instance to pan to the clicked location
    if (map && clickedLatLng) {
      map.panTo(clickedLatLng); // Smoothly pans the map to the clicked coordinates
    }
    mapTRef.current && clearTimeout(mapTRef.current);
    mapTRef.current = setTimeout(() => {
      setCrds({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
      fetchLocationFromLatLong({
        coords: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
      });
    }, 500);
  };

  useEffect(() => {
    getExistingUsers();
    getExistingLocations(localStorage.getItem("user_id"));
    try {
      navigator.geolocation.getCurrentPosition(
        (coords) => {
          setCrds({
            lat: coords.coords.latitude,
            lng: coords.coords.longitude,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {}
  }, [checkExisting, step]);

  // useEffect(() => {
  //   let addressTimer;

  //   if (isAddressChanged) {
  //     addressTimer = setTimeout(() => {
  //       if (isAddressChanged) {
  //         setIsMapOpen(true);
  //         setIsAddressChanged(false);
  //       }
  //     }, 2000);
  //   }

  //   return () => {
  //     addressTimer && clearTimeout(addressTimer);
  //   };
  // }, [isAddressChanged]);

  const handleSelectExisting = (item) => {
    console.log("hanndleSelectedE", item)
    item.id && setSelectedExisting(item.id);
    item.first_name && setFirstName(item.first_name);
    item.last_name && setLastName(item.last_name);
    item.age && setAge(String(item.age));
    item.gender && setGender(item.gender);
    setHealthConcerns(item?.healthConcerns || localStorage.getItem("healthConcerns"));
    setCheckExisting(true);
    localStorage.setItem("user_id", item.id);
    localStorage.setItem("selected_user_id", item.id);
  };

  const handleSelectAddress = (item) => {
    setIsAddressChanged(false);
    item?.id && setSelectedAddress(item.id);
    setApartment(item?.apartment || localStorage.getItem("apartment"));
    item?.address && setArea(item.address);
    item?.address && setQuery(item.address);
    item?.floor_number && setHouseNo(item.floor_number);
    item?.city && setCity(item?.city);
    item?.city && setCity(item?.state);
    // item?.zipcode && setPostalCode(String(item?.zipcode));
    item?.lat && setCrds({ lat: item.lat, lng: item.lng });
    setCheckExistingAddress(true);
  };

  const resetUSerStates = () => {
    setSelectedExisting("");
    setCheckExisting(false);
    setCheckExistingAddress(false);
    setFirstName("");
    setLastName("");
    setAge("");
    setGender("");
    setHealthConcerns("");
  };

  const resetAddresStates = () => {
    setSelectedAddress("");
    setArea("");
    setApartment("");
    setHouseNo("");
    setQuery("");
    // setPostalCode("");
    setCheckExistingAddress(false);
  };

  return (
    <div className="form-container-v1">
      <HeaderLogo />
      <SpecialistBanner />
      <Steps currentStep={step === 1 ? 2 : 3} />
      <PriceBAnner />
      <ToastContainer />
      {heavyModal ? (
        <div style={{ position: "fixed", inset: "0", background: "#fff" }}>
          <ETAFile text={bookingErr} time={300} />
        </div>
      ) : (
        ""
      )}
      <OverlayLoader
        showText={step === 2}
        loading={APILoader}
        color="#169d82"
      />
      {step === 1 ? (
        <>
          {" "}
          {existingUsers.length > 0 ? (
            <>
              <h2>Please provide details for whom you are booking for</h2>
              <h3
                className="just-few-text"
                style={{ marginTop: "16px", fontWeight: "500" }}
              >
                Select from existing users
              </h3>

              <div className="existing-container">
                {existingUsers.map((each, i) => {
                  return (
                    <label
                      key={i}
                      htmlFor={`${each?.id}`}
                      className={`existing-card ${
                        selectedExisting === each?.id ? "active-selection" : ""
                      }`}
                    >
                      <div className="name-container">
                        <p className="existing-name">{each?.first_name}</p>
                        <p className="existing-para">
                          {each?.age} {","} {each?.gender}
                        </p>
                      </div>
                      <input
                        type="radio"
                        id={`${each.id}`}
                        name="existing-selection"
                        className="existing-check"
                        checked={each.id === selectedExisting}
                        onClick={() => {
                          if (each.id === selectedExisting) {
                            resetUSerStates(); // Uncheck when clicked again
                          } else {
                            handleSelectExisting(each);
                          }
                        }}
                      />
                    </label>
                  );
                })}
              </div>
              {!selectedExisting && <Separator />}
              {!selectedExisting && (
                <div
                  style={{
                    width: "100%",
                    fontWeight: "500",
                    fontSize: "14px",
                    marginLeft: "12px",
                  }}
                >
                  Add new user
                </div>
              )}
            </>
          ) : (
            <>
              <h3 className="just-few-text">Just a few details left!</h3>
              <h2>{"Complete your profile"}</h2>
            </>
          )}
          <form>
            <div className="form-row">
              <div className="form-group">
                <label>First Name*</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter First Name"
                />
                {errors.firstName && (
                  <span className="error">{errors.firstName}</span>
                )}
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter Last Name"
                />
                {errors.lastName && (
                  <span className="error">{errors.lastName}</span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Age*</label>
                <input
                  type="number"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  placeholder="Enter Age"
                />
                {errors.age && <span className="error">{errors.age}</span>}
              </div>
              <div className="form-group">
                <label>Gender*</label>
                {/* <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select> */}

                <Select
                  style={{
                    borderRadius: "10px",
                    padding: "0 10px",
                    color: "#333333",
                    fontSize: "14px",
                    width: "100%",
                  }}
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem
                    style={{
                      padding: "0 10px",
                      lineHeight: "40px",
                      minHeight: "0px",
                    }}
                    value=""
                    disabled
                  >
                    <em> Select Gender *</em>
                  </MenuItem>
                  <MenuItem
                    style={{
                      padding: "0 10px",
                      lineHeight: "40px",
                      minHeight: "0px",
                    }}
                    value="Male"
                  >
                    Male
                  </MenuItem>
                  <MenuItem
                    style={{
                      padding: "0 10px",
                      lineHeight: "40px",
                      minHeight: "0px",
                    }}
                    value="Female"
                  >
                    Female
                  </MenuItem>
                </Select>
                {errors.gender && (
                  <span className="error">{errors.gender}</span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Health Concerns*</label>
                <textarea
                  value={healthConcerns}
                  onChange={(e) => setHealthConcerns(e.target.value)}
                  placeholder="Enter Health Concerns"
                  rows="4"
                />
                {errors.healthConcerns && (
                  <span className="error">{errors.healthConcerns}</span>
                )}
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          {isMapOpen && isLoaded && (
            <MapComponent
              setIsMapOpen={setIsMapOpen}
              city={city}
              setCity={setCity}
              setQuery={setQuery}
              setCrds={setCrds}
              query={query}
              crds={crds}
              setArea={setArea}
              fetchLocationFromLatLong={fetchLocationFromLatLong}
              setMapAddressChange={setMapAddressChange}
            />
          )}

          <h2>Add your address To send a doctor without delay!</h2>
          {existinglocations.length > 0 && (
            <>
              <p className="just-few-text">Select from existing adresses</p>{" "}
              <div className="existing-container">
                {existinglocations.map((each, i) => (
                  <label
                    key={i}
                    htmlFor={`${each?.id}`}
                    className={`existing-card  existing-address ${
                      selectedAddress === each?.id ? "active-selection" : ""
                    }`}
                  >
                    <div className="">
                      <p
                        className="existing-name"
                        style={{
                          width: "210px",
                          height: "auto",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {each?.floor_number}, {each?.address}
                      </p>
                      <p className="existing-para">{each?.area}</p>
                    </div>
                    <input
                      type="radio"
                      id={`${each.id}`}
                      name="existing-selection"
                      className="existing-check"
                      checked={each.id === selectedAddress}
                      onClick={() => {
                        if (each.id === selectedAddress) {
                          resetAddresStates(); // Uncheck when clicking again
                        } else {
                          handleSelectAddress(each);
                        }
                      }}
                    />
                  </label>
                ))}
              </div>
              {!selectedAddress && <Separator />}
            </>
          )}
          <div className="detect-container">
            <button className="detect-btn" onClick={() => getLocation()}>
              <DetectIcon /> Add new address
            </button>
            <button className="clear-btn" onClick={() => resetAddresStates()}>
              Clear
            </button>
          </div>
          <div style={{ width: "100%" }}>
            {query && (
              <p
                style={{
                  fontSize: "12px",
                  color: "#169d82",
                  marginTop: "8px",
                  marginLeft: "12px",
                }}
              >
                Location fetched successfully, add more details
              </p>
            )}
          </div>
          <form>
            <div className="form-group">
              {/* <label>House/Flat/Block No.</label> */}
              <label>Floor</label>

              <input
                type="text"
                value={houseNo}
                onChange={(e) => setHouseNo(e.target.value)}
                placeholder="Enter Floor"
                disabled={!query}
                style={{ opacity: !query ? "50%" : "" }}
              />
              {errors.houseNo && (
                <span className="error">{errors.houseNo}</span>
              )}
            </div>
            <div className="form-group" style={{ marginBottom: "18px" }}>
              {/* <label>Apartment/Road/Area</label> */}

              <label>Apartment/Building/House Number*</label>
              <input
                type="text"
                value={apartment}
                onChange={(e) => setApartment(e.target.value)}
                placeholder="Enter Apartment/Building/House Number"
                disabled={!query}
                style={{ opacity: !query ? "50%" : "" }}
              />
              {errors.apartment && (
                <span className="error">{errors.apartment}</span>
              )}
            </div>{" "}
            {/* <div className="form-group">
              <label>Area/Sector/Locality*</label>
              <input
                type="text"
                value={area}
                onChange={(e) => {
                  setArea(e.target.value);
                  setAdditionalSearch(e.target.value);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setAdditionalSearch("");
                  }, 500);
                }}
                onClick={() => {
                  // setIsMapOpen(true);
                  setMapAddressChange(true);
                }}
                placeholder="Enter Area/Sector/Locality"
              /> */}
            {/* {additionalSearch && (
                <SearchInput
                  fetchLocationFromLatLong={fetchLocationFromLatLong}
                  styles={{ display: "none" }}
                  additionalSearch={additionalSearch}
                />
              )} */}
            {/* {errors.area && <span className="error">{errors.area}</span>} */}
            {/* </div> */}
            {/* <div className="form-row">
              <div className="form-group">
                <label>Postal/Zip Code*</label>
                <input
                  type="text"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeholder="Enter Postal/Zip Code"
                  maxLength="6"
                />
                {errors.postalCode && (
                  <span className="error">{errors.postalCode}</span>
                )}
              </div>
            </div> */}
          </form>
        </>
      )}

      <BrandButton
        className={"w-full "}
        label={step === 1 ? "Continue" : "Submit"}
        onClick={handleContinue}
        disabled={
          step === 1
            ? !firstName || !age || !gender || !healthConcerns
            : !apartment
        }
      />

      <HappyFamily />
    </div>
  );
};

export default PersonalInfoForm;

// const MapComponent = ({ crds, fetchLocationFromLatLong, setArea, query }) => {
//   const markerRef = useRef(null);

//   const map = useMap();

//   useEffect(() => {
//     map.setView(
//       { lat: crds?.lat || 28.64, lng: crds?.lng || 77.21 },
//       map.getZoom()
//     );
//   }, [crds]);

//   useEffect(() => {
//     setArea(query);
//   }, []);

//   return (
//     <>
//       <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
//       <Marker
//         ref={markerRef}
//         eventHandlers={{
//           dragend(e) {
//             const marker = markerRef.current;
//             if (marker != null) {
//               console.log(marker, marker.getLatLng());
//               // setCrds(marker.getLatLng());

//               setTimeout(() => {
//                 map.setView(marker.getLatLng(), map.getZoom());
//               }, 300);

//               fetchLocationFromLatLong({
//                 coords: {
//                   latitude: marker.getLatLng().lat,
//                   longitude: marker.getLatLng().lng,
//                 },
//               });
//             }
//           },
//         }}
//         icon={customIcon}
//         position={{ lat: crds?.lat || 28.64, lng: crds?.lng || 77.21 }}
//         draggable
//       ></Marker>
//     </>
//   );
// };
