import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

export default function DoctorScreen({ appointment }) {
    const [ETA, setETA] = React.useState(0);
    const [doctorDetails, setDoctorDetails] = useState(null);
    console.log({ appointment })
    useEffect(() => {
        if (appointment) {
            console.log("appointment", appointment, appointment.doctor)
            let ETA = 0
            if (appointment.appointment_start_time) {
                const currentTime = new Date()
                const appointmentStartTime = new Date(appointment.appointment_start_time)
                const diff = appointmentStartTime - currentTime
                ETA = Math.floor(diff / 60000)
            }

            setETA(ETA < 5 ? 5 : ETA)

            setDoctorDetails(appointment?.doctor)

        }

    }, [appointment])

    useEffect(() => {
        if (ETA <= 0) return;

        const timer = setInterval(() => {
            setETA(prevTime => prevTime - 1);
        }, 60000); // Subtract 1 every 60,000 ms (1 minute)

        return () => clearInterval(timer); // Cleanup on unmount

    }, [ETA])

    const handleCancel = async () => {
        const raw = JSON.stringify({
            id: appointment.id,
            appointment_cancellation: {
                cancelled_by: "patient",
                reason: "Reason for cancellation",
            }
        });
        const myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        myHeaders.append("token", sessionStorage.getItem('token'));
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        await fetch(process.env.REACT_APP_KMS_BASE_URL + `/api/customers/appointments/${appointment.id}/cancel`, requestOptions)
            .then(response => response.text())
            .then(data => {
                console.log({ data })
            })
            .catch(error => {
                console.error(error);
            });
        // Handle cancel logic here
    };

    // const [loader, setLoader] = React.useState(false);
    // const [doctorDetails, setDoctorDetails] = React.useState({});
    // useEffect(() => {
    //     setLoader(true);
    //     fetch(process.env.REACT_APP_KMS_BASE_URL + "/api/customers/view_doctor?id=1785", {
    //         method: "GET",
    //         headers: { "Content-Type": "application/json", token: sessionStorage.getItem('token') }
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log({ data })
    //             setLoader(false);
    //             setDoctorDetails(data);
    //         })
    //         .catch(error => {
    //             setLoader(false);
    //             console.log(error);
    //         });
    // }, []);
    const onReset = () => {
        window.location.reload()
    }
    console.log({ doctorDetails, ETA })
    if (!doctorDetails && appointment) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
            </Helmet>
            {appointment?.id
                ? <div className="doctor-screen-container">
                    <div className="doctor-screen-container-header">
                        <img src="/images/kyno.svg" />
                        {/* <span className="header-right">Doctor at home<br /><span style={{ color: "#FFA500" }}>in 60
                            Minutes</span></span> */}
                    </div>
                    <div className="doctor-screen-container-inner">
                        <div className="doctor-screen-container-inner-doctordetails">

                            <div className="doctor-screen-container-inner-image" style={{
                                // background: `url(${doctorDetails.avatar_url})`,
                                backgroundSize: "cover",
                                backgroundPosition: "top"
                            }}>
                                <img src={`${doctorDetails.avatar_url})`} />
                            </div>
                            <div className="doctor-screen-container-inner-content">
                                <h2 className="doctor-screen-container-inner-content-docname">Dr. {doctorDetails?.user_full_name}</h2>
                                <p className="doctor-screen-container-inner-content-speciality">{doctorDetails?.doctor_detail
                                    ?.specialization} ({doctorDetails?.doctor_detail?.qualifications})</p>

                                <span className="doctor-screen-container-inner-content-rating"
                                ><img width="20px" height="20px" src="/images/location_white_1.png" />
                                    {doctorDetails?.doctor_detail?.location}</span>

                                <div className="doctor-screen-container-inner-content-details">
                                    <div>
                                        <p className="doctor-screen-container-inner-content-details-label">Kyno Visits</p>
                                        <p className="doctor-screen-container-inner-content-details-number">{doctorDetails?.doctor_detail?.kyno_visits_count}+</p>
                                    </div>
                                    <div>
                                        <p className="doctor-screen-container-inner-content-details-label">Rating &#11088;</p>
                                        <p className="doctor-screen-container-inner-content-details-number">{doctorDetails?.doctor_detail?.rating}<span>({doctorDetails?.doctor_detail
                                            ?.reviews_count})</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="doctor-screen-container-inner-medical">
                            <div>
                                <img src={doctorDetails?.doctor_detail?.medical_logo} alt="Medical Registration" />
                            </div>
                            <div>
                                <p style={{ margin: "0px", fontWeight: "500" }}>Medical Registration</p>
                                <p style={{ fontWeight: "bold", margin: "0px" }}>{doctorDetails?.doctor_detail?.registration_number}</p>
                            </div>
                        </div>

                        <h4 className="doctor-screen-container-inner-bottom"
                        >Previous Experience</h4>
                        <p className="doctor-screen-container-inner-para">{doctorDetails?.doctor_detail
                            ?.experience}</p>
                    </div>
                    {/* <div className="doctor-screen-container-eta">
                        ETA - <span>{(ETA > 0) ? `${ETA} minutes` : "Arriving soon"}</span>
                    </div> */}
                    {/* <div className="doctor-screen-container-bottom-btn">
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                                height: "40px",
                                width: "auto",
                                fontWeight: "600",
                                padding: "10px 15px",
                                gap: "8px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                background: "#FB9F40"
                            }}
                            onClick={(e) => {
                                window.location.href = `/booking/confirmation/?appId=${appointment.id}`;
                            }}
                        >
                            {"Confirm Booking"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                                height: "40px",
                                width: "auto",
                                fontWeight: "600",
                                padding: "10px 15px",
                                gap: "8px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                background: "grey",
                                color: "#000",
                            }}
                            onClick={(e) => handleCancel(e)}
                        >
                            {"Cancel"}
                        </Button>
                    </div> */}
                </div>
                : <Typography style={{ fontSize: '14px', color: "#00000080", textAlign: "center", width: "90%", margin: "0 auto", maxWidth: "500px" }}>
                    <div className="booking_thanyou_slot_details" style={{
                        background: "#edcaaa",
                        padding: "10px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        color: "black",
                        textAlign: "center",
                        maxWidth: "400px",
                        margin: "0 auto",
                    }}>
                        We regret the inconvenience, kindly contact us at <a href="tel:9953104104">9953104104</a> to manually schedule your appointment.
                    </div>
                    {/* <Button variant="contained" color="primary" onClick={() => onReset()} style={{
                        margin: "20px auto",
                        marginTop: '20px',
                        maxWidth: "343px",
                        fontWeight: "600",
                        padding: "0px 16px",
                        gap: "8px",
                        borderRadius: "10px",
                        background: "#FB9F40",
                        color: "#fff",
                        lineHeight: "40px"
                    }}>
                        Back To Homepage
                    </Button> */}
                </Typography>}
        </div>

    );
}