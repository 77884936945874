import React, { useEffect, useState } from "react";
import "../Counting/Css/style.scss";

const Counting = () => {
  const [patients, setPatients] = useState(0);
  const [doctors, setDoctors] = useState(0);
  const [duration, setDuration] = useState(0);
  const [rating, setRating] = useState(0);

  // Function to animate numbers dynamically
  const animateCount = (target, setter, duration = 2000) => {
    let start = 0;
    const stepTime = Math.abs(Math.floor(duration / target));

    const timer = setInterval(() => {
      start += 100;
      setter(start);

      if (start >= target) {
        setter(target);
        clearInterval(timer);
      }
    }, stepTime);
  };

  // Animate numbers on component mount
  useEffect(() => {
    animateCount(20000, setPatients);
    animateCount(50, setDoctors, 1500);
    animateCount(30, setDuration, 1000);
    animateCount(4.9, setRating, 1000);
  }, []);

  return (
    <div className="stats-counter">
      <div className="stat-item">
        <h2>{patients.toLocaleString()}+</h2>
        <p>Happy families</p>
      </div>

      <div className="stat-item">
        <h2>{doctors}+</h2>
        <p>Doctors</p>
      </div>

      <div className="stat-item">
        <h2>{duration} mins</h2>
        <p>Average consultation duration</p>
      </div>

      <div className="stat-item">
        <h2>
          {rating.toFixed(1)} <span className="star">⭐</span>
        </h2>
        <p>Google rating</p>
      </div>
    </div>
  );
};

export default Counting;
