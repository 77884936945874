import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';

const OverlayLoader = ({ loading, initialCountdown = 10, color = '#36d7b7', showText = true }) => {
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    let timer;
    if (loading && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }

    if (countdown <= 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [loading, countdown]);

  useEffect(() => {
    if (loading) {
      setCountdown(initialCountdown);
    }
  }, [loading, initialCountdown]);

  if (!loading) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.loaderContainer}>
        <ScaleLoader loading={loading} color={color} />
        {showText && <div style={styles.text}>
          Finalizing details... {countdown ?   `in ${countdown} seconds` : ''}
        </div>}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    marginTop: '10px',
    color: '#fff',
    fontSize: '14px',
  },
};

export default OverlayLoader;
