import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./components/Home";
import BookAppointmentPage from "./components/bookAppointment";
import AdPage from "./components/AdPage";
import PageNotFound from "./components/PageNotFound";
import BookingConfirm from "./components/bookingConfirm";
import Disease from "./components/seoPages/disease";
import Test from "./components/test";
import DashboardHome from "./components/dashboard/DashboardHome";
import AdCMS from "./components/Ad_cms/Ad_Cms";
import DiseaseSymptomsTreatment from "./components/Disease-Symptoms-Treatment/Disease_Symptoms_Treatment";
import PatientInfoPage from "./components/dashboard/sales/PatientInfoPage";
import SalesHome from "./components/dashboard/sales/Sales_home";
import TNC from "./components/TermsAndConditions";
import RefundPolicy from "./components/refund_policy";
import BookAppointment from "./components/dashboard/sales/bookAppointment";
// import Services from './components/home_page_v2/Services/index'
// import Meet_our_Doctors from './components/home_page_v2/Meet_our_doctor/index'
// import AboutUs from './components/home_page_v2/About_Us/index'
//import Home_v2 from './components/home_v2';
import HomeNew from "./components/home_v2";
import HomeNewBookingSlot from "./components/home_book_slot";
// 11-08-2023

import DoctorsNearMe from "./components/home_page_v2/Doctors-near-me/index";
// import Physician_near_me from './components/home_page_v2/physician-near-me/index';
import DoctorHomeVisitNoida from "./components/home_page_v2/Doctor-home-visit-noida/index";
import ScheduleDoctorAppointment from "./components/home_page_v2/Schedule-doctor-appointment/index";

import IvTherapy from "./components/home_page_v2/Iv-therapy/index";
import TreatmentForBedSore from "./components/home_page_v2/Treatment-for-bed-sore/index";
import DoctorOnCallHomeVisit from "./components/home_page_v2/Doctor-on-call-home-visit/index";
import TwentyFourHourVisitingDoctor from "./components/home_page_v2/Twenty-four-hour-visiting-doctor/index";
import DoctorForFeverTreatment from "./components/home_page_v2/Doctor-for-fever-treatment/index";
import DoctorForWeaknessTreatment from "./components/home_page_v2/Doctor-for-weakness-treatment/index";
import TreatmentForDizziness from "./components/home_page_v2/Treatment-for-dizziness/index";
import TreatmentForVomiting from "./components/home_page_v2/Treatment-for-vomiting/index";
import TreatmentForLooseMotion from "./components/home_page_v2/Treatment-for-loose-motion/index";
import DoctorForPainManagement from "./components/home_page_v2/Doctor-for-pain-management/index";
import BloodPressureDoctor from "./components/home_page_v2/Blood-pressure-doctor/index";
import DoctorForHypertension from "./components/home_page_v2/Doctor-for-hypertension/index";
import DoctorForStomachIssues from "./components/home_page_v2/Doctor-for-stomach-issues/index";
import WoundDressingAtHome from "./components/home_page_v2/Wound-dressing-at-home/index";
import IvTreatmentAtHome from "./components/home_page_v2/Iv-treatment-at-home/index";
import FullBodyHealthCheckup from "./components/home_page_v2/Full-body-health-checkup/index";
import GeneralHealthCheckup from "./components/home_page_v2/General-health-checkup/index";
import DoctorForMinorInjuries from "./components/home_page_v2/Doctor-for-minor-injuries/index";
import DoctorForAnimalBite from "./components/home_page_v2/Doctor-for-animal-bite/index";
import ContactUs from "./components/home_page_v2/Contact-Us/index";
import PrivacyPolicy from "./components/home_page_v2/privacy-policy/index";
import ReturnPolicy from "./components/home_page_v2/return-policy/index";
import TermsConditions from "./components/home_page_v2/terms-conditions/index";
import PediatricianAtHomeIn60Minutes from "./components/home_page_v2/pediatrician-at-home-in-60-minutes";
import Top10BenefitsofHomeVisitDoctors from "./components/home_page_v2/Top-10-benefits-home-visit-doctors/index";
import TipsforFindingtheBestHomeVisitDoctors from "./components/home_page_v2/Tips-for-Finding-the-Best-Home-Visit-Doctors/index";
// import Blogsection from './components/home_page_v2/Blog/index'
import BenefitsDoctorHomeServicesNoida from "./components/home_page_v2/Benefits-Doctor-Home-Services-Noida/index";
import ImportanceSchedulingAttendingDoctorAppointments from "./components/home_page_v2/Importance-Scheduling-Attending-Doctor-Appointments/index";
import BenefitsRegularDoctorVisits from "./components/home_page_v2/Benefits-Regular-Doctor-Visits/index";
import RoleofGeneralPhysicians from "./components/home_page_v2/Role-of-General-Physicians/index";
import TipsToReliefFromHighFever from "./components/home_page_v2/Tips-To-Relief-From-High-Fever/index";
import HowtoControlBloodPressure from "./components/home_page_v2/How-to-Control-Blood-Pressure/index";
import WhatisDiabetes from "./components/home_page_v2/What-is-Diabetes/index";
import FindReliableHomeVisitDoctorsGuide from "./components/home_page_v2/Find-Reliable-Home-Visit-Doctors-Guide/index";

import HerbalRemediesforCough from "./components/home_page_v2/Herbal-Remedies-for-Cough/index";
import HerbalRemediesforDRYCough from "./components/home_page_v2/Home-Remedies-for-Dry-Cough/index";
import PrescriptionMedicationsforDryCough from "./components/home_page_v2/Prescription-Medications-for-Dry-Cough/index";
import UnderstandingDryCough from "./components/home_page_v2/Understanding-Dry-Cough/index";
import PerformanceGoogle from "./components/home_page_v2/Performance/Google";
import PerformancePhysician from "./components/home_page_v2/Performance/Physician";
import BestDandruffFightingRoutine from "./components/home_page_v2/Best-Dandruff-Fighting-Routine";
import UnderstandingtheCausesofDandruffandItchyScalp from "./components/home_page_v2/Understanding-the-Causes-of-Dandruff-and-Itchy-Scalp";
import Top10HomeRemediesforEffectiveDandruffTreatment from "./components/home_page_v2/Top-10-Home-Remedies-for-Effective-Dandruff-Treatment";
import TopHomeRemediesforDandruff from "./components/home_page_v2/Top-Home-Remedies-for-Dandruff";
import DosandDontsforSupportingSomeoneDuringSeizures from "./components/home_page_v2/Dos-and-Donts-for-Supporting-Someone-During-Seizures";
import EmergencyHomeVisitDoctorServiceinDelhi from "./components/home_page_v2/Emergency-Home-Visit-Doctor-Service-in-Delhi";
import EmergencyHomeVisitDoctorServiceinGurgaon from "./components/home_page_v2/Emergency-Home-Visit-Doctor-Service-in-Gurgaon";
import EmergencyHomeVisitDoctorServiceinBangalore from "./components/home_page_v2/Emergency-Home-Visit-Doctor-Service-in-Bangalore";
import StaySafethisSummer from "./components/home_page_v2/Stay-Safe-this-Summer";
import HeatwaveAlertExpertTipstoBeattheHeat from "./components/home_page_v2/Heatwave-Alert-Expert-Tips-to-Beat-the-Heat";
import MasteringMonsoonHealthExpertTips from "./components/home_page_v2/Mastering-Monsoon-Health-Expert-Tips-to-Avoid-Getting-Sick";
import PartnershipPlum from "./components/home_page_v2/Partnership-Plum";
import GPhysiciansYamunaViharDelhi from "./components/home_page_v2/General-Physicians/home-in-yamuna-vihar-delhi";
import GPhysiciansShahdaraDelhi from "./components/home_page_v2/General-Physicians/home-in-shahdara-delhi";
import GPhysiciansSaritaViharDelhi from "./components/home_page_v2/General-Physicians/home-in-sarita-vihar-delhi";
import GPhysiciansRajouriGardenDelhi from "./components/home_page_v2/General-Physicians/rajouri-garden-general-physicians-at-home";
import GPhysiciansPunjabiBaghDelhi from "./components/home_page_v2/General-Physicians/punjabi-bagh-general-physicians-at-home";
import GPhysiciansPreetViharDelhi from "./components/home_page_v2/General-Physicians/general-physicians-at-home-preet-vihar-delhi";
import GPhysiciansPatelNagarDelhi from "./components/home_page_v2/General-Physicians/patel-nagar-general-physicians-at-home";
import GPhysiciansNajafgarh from "./components/home_page_v2/General-Physicians/najafgarh-general-physicians-home";
import GPhysicianskapashera from "./components/home_page_v2/General-Physicians/kapashera-general-physicians-at-home";
import GPhysiciansGandhiNagarDelhi from "./components/home_page_v2/General-Physicians/general-physicians-at-home-in-gandhi-nagar-delhi";
import GPhysiciansDwarka from "./components/home_page_v2/General-Physicians/dwarka-general-physicians-at-home";
import GPhysiciansDefenceColonyDelhi from "./components/home_page_v2/General-Physicians/general-physicians-home-in-defence-colony-delhi";
import FuelingYourWorkday from "./components/home_page_v2/Blog/Fueling-Your-Workday";
import TackleIdiotSyndrome from "./components/home_page_v2/Blog/Tackle-Idiot-Syndrome";
import PostSurgeryRecoveryTips from "./components/home_page_v2/Blog/Post-Surgery-Recovery-Tips";
import BreastFeedingNature from "./components/home_page_v2/Blog/breastfeeding-perfect-gift-mom-baby";
import NavigateBPIssues from "./components/home_page_v2/Blog/navigate-bp-issues-monitor-blood-pressure";
import GPhysiciansAlipur from "./components/home_page_v2/General-Physicians/home-in-alipur";
import GPhysiciansModelTown from "./components/home_page_v2/General-Physicians/home-in-model-town";
import GPhysiciansHomeNarela from "./components/home_page_v2/General-Physicians/home-in-narela";
import GPhysiciansCivilLines from "./components/home_page_v2/General-Physicians/home-in-civil-lines";

import GPhysiciansHomeSaket from "./components/home_page_v2/General-Physicians/home-in-saket";
import GPhysiciansHomeHauzkhas from "./components/home_page_v2/General-Physicians/home-in-hauz-khas";
import GPhysiciansHomeMehrauli from "./components/home_page_v2/General-Physicians/home-in-mehrauli";
import GPhysiciansHomedefencecolony from "./components/home_page_v2/General-Physicians/home-in-defence-colony";
import GPhysiciansHomeKanjhawala from "./components/home_page_v2/General-Physicians/home-in-kanjhawala";
import GPhysiciansHomeKalkaji from "./components/home_page_v2/General-Physicians/home-in-kalkaji";
import BookingForm from "./components/booking";
import SPFInMonsoon from "./components/home_page_v2/Blog/spf-in-monsoon";
import PaymentSuccess from "./components/booking/thankyou";
import CaringElderly from "./components/home_page_v2/Blog/caring-for-the-elderly-at-home";
import VectorBorneDiseases from "./components/home_page_v2/Blog/vector-borne-diseases";
import BlogHome from "./components/home_page_v2/Blog/index";
import UnderstandingFeverWhentoVisitDoctor from "./components/home_page_v2/Blog/understanding-fever-when-to-consult";
import IVTherapyatHomeSafetyandBenefitsExplained from "./components/home_page_v2/Blog/iv-therapy-at-home-safety-benefits";
import WhyEveryFamilyNeedsGeneralPhysicianEssentialGuide from "./components/home_page_v2/Blog/why-every-family-needs-general-physician";
import ComprehensiveGuideGeneralHealthCheckUp from "./components/home_page_v2/Blog/comprehensive-guide-general-health-checkup";
import SoreThroatNaturalHomeRemedies from "./components/home_page_v2/Blog/sore-throat-natural-home-remedies";
import Top7RemediestoEaseThroatAche from "./components/home_page_v2/Blog/top-7-remedies-ease-throat-ache-at-home";
import GuidetoHealingThroatInfectionsNaturally from "./components/home_page_v2/Blog/guide-healing-throat-infections-naturally";
import GoodbyetoThroatPainTrustedHomeRemedies from "./components/home_page_v2/Blog/goodbye-to-throat-pain-home-remedies";
import AcidityHomeRemediesFastNaturalRelief from "./components/home_page_v2/Blog/acidity-home-remedies-fast-natural-relief";
import HyperAcidityHomeRemediesPowerfulFixesforImmediateRelief from "./components/home_page_v2/Blog/hyper-acidity-home-remedies-immediate-relief";
import FastActingHomeRemediesHeartburnAcidReflux from "./components/home_page_v2/Blog/home-remedies-heartburn-acid-reflux-relief";
import CommonAciditySymptomsHowtoSpotThemEarly from "./components/home_page_v2/Blog/acidity-symptoms-early-warning-signs";
import StoreLocator from "./components/home_page_v2/Locations/store-locator";
import MobileInputWithTruecaller from "./components/booking/truecaller";
import CityBookAppointmentGoogle from "./components/city-book-appointment-google";
import CityBookAppointmentHero from "./components/home_page_v2/Hero/CityBookAppointmentHero";
import CityGoogleBookAppointment from "./components/booking/CityGoogleBookAppointment";

import Lending from "./components/lending";
import ProvideServices from "./components/lendingPage/ProvideServices/index";
import AboutUs from "./components/lendingPage/AboutUs/index";
import NewContactUs from "./components/lendingPage/ContactUs";
import BookingDetails from "./components/lendingPage/BookingDetails/index";
import BookingSuccessfull from "./components/lendingPage/BookingSuccessfull/index";
import BookingFormNewFlow from "./components/booking/bookingNewFlow";
import BookingFormNewFlowv2 from "./components/booking/bookingNewFlow-v2";
import Bookingv3 from "./components/booking/landing_10min";
import Bookingv3WithOTP from "./components/booking/landing_10min_withOTP";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/Authenticate/AuthContext";
import GetMobileNumber from "./components/ProtectRoute/LoginScreen";
import { Person } from "@mui/icons-material";
import PersonalInfoForm from "./components/ProtectRoute/PersonalInfoForm";
import BookingPage from "./components/ProtectRoute/BookingPage";
import BookDoctor from "./components/ProtectRoute/LoginScreen/BookDoctor";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Temporarily disable smooth scrolling for instant effect
    const html = document.documentElement;
    const originalScrollBehavior = html.style.scrollBehavior;

    html.style.scrollBehavior = "auto"; // Disable smooth scroll
    window.scrollTo(0, 0);

    // Restore original scroll behavior after a short delay
    setTimeout(() => {
      html.style.scrollBehavior = originalScrollBehavior;
    }, 100); // Delay to prevent smooth scrolling on immediate navigation
  }, [pathname]); // Runs whenever the route changes

  return null;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <ScrollToTop />
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<Lending />} />
          <Route exact path="/provide-services" element={<ProvideServices />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<NewContactUs />} />
          {/* <Route exact path="/booking-details" element={<BookingDetails />} /> */}
          {/* <Route exact path="/booking-successfull" element={<BookingSuccessfull />} /> */}

          {/* <Route exact path="/" element={<HomeNew />} />     */}
          <Route
            exact
            path="/google-book-appointment"
            element={<HomeNewBookingSlot />}
          />
          <Route
            exact
            path="/:city-google-book-appointment"
            element={<HomeNewBookingSlot />}
          />
          {/* <Route exact path="/" element={<HomeNew />} /> */}
          <Route
            exact
            path="/google-book-appointment"
            element={<HomeNewBookingSlot />}
          />
          <Route
            exact
            path="/:city-google-book-appointment"
            element={<HomeNewBookingSlot />}
          />
          <Route
            exact
            path="/:city-google-book-appointment-v2"
            element={<HomeNewBookingSlot newContent={true} />}
          />
          <Route
            exact
            path="/google-call-appointment"
            element={<HomeNewBookingSlot customCallus={"01206056620"} />}
          />
          <Route
            exact
            path="/:city-book-appointment-google"
            element={<CityBookAppointmentGoogle />}
          />
          <Route
            exact
            path="/:city-book-appointment-google-checkout"
            element={<CityGoogleBookAppointment />}
          />
          <Route
            exact
            path="/bookAppointment/"
            element={<BookAppointmentPage />}
          />
          {/* <Route exact path="/doctor-at-home-in-60-minutes/" element={<AdPage />} /> */}
          <Route exact path="/bookingConfirmed" element={<BookingConfirm />} />
          <Route exact path="/disease" element={<Disease />} />
          <Route exact path="/test" element={<Test />} />
          <Route exact path="/internalSite" element={<DashboardHome />} />
          <Route exact path="/terms-and-conditions" element={<TNC />} />
          <Route exact path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/dashboard">
            <Route path="" element={<DashboardHome />} />
            <Route path="sales" element={<SalesHome />} />
            <Route
              path="patient-information-page"
              element={<PatientInfoPage />}
            />
            <Route
              path="patient-book-appointment"
              element={<BookAppointment />}
            />
          </Route>
          {/* <Route exact path='/ad-cms' element={<Ad_CMS />} /> */}
          {/* <Route path='/:id' element={<Disease_symptoms_treatment normal={"id"} />} /> */}
          <Route exact path="/a/b" element={<Home />} />
          {/* <Route  path='/:id' element={<Disease_symptoms_treatment />} /> */}
          <Route path="/ad-cms">
            <Route path="" element={<AdCMS />} />
            <Route path=":id" element={<AdCMS />} />
          </Route>
          <Route path="/op/Disease-symptoms-treatment">
            <Route index={true} element={<Home />} />
            <Route
              index={false}
              path=":id"
              element={<DiseaseSymptomsTreatment normal="id" />}
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/doctors-near-me" element={<DoctorsNearMe />} />
          {/* <Route exact path='/physician-near-me' element={<Physician_near_me />} />         */}
          <Route
            path="/doctor-home-visit-noida"
            element={<DoctorHomeVisitNoida />}
          />
          {/* <Route
            path="/schedule-doctor-appointment"
            element={<ScheduleDoctorAppointment />}
          /> */}
          <Route path="/iv-therapy" element={<IvTherapy />} />
          <Route
            path="/treatment-for-bed-sore"
            element={<TreatmentForBedSore />}
          />
          <Route
            path="/doctor-on-call-home-visit"
            element={<DoctorOnCallHomeVisit />}
          />
          <Route
            path="/twenty-four-hour-visiting-doctor"
            element={<TwentyFourHourVisitingDoctor />}
          />
          <Route
            path="/doctor-for-fever-treatment"
            element={<DoctorForFeverTreatment />}
          />
          <Route
            path="/doctor-for-weakness-treatment"
            element={<DoctorForWeaknessTreatment />}
          />
          {/* <Route path='/services' element={<Services />} /> */}
          {/* <Route path='/meet_our_doctors' element={<Meet_our_Doctors/>}/> */}
          {/* <Route exact path='/about-us' element={<AboutUs/>}/> */}
          <Route
            path="/treatment-for-dizziness"
            element={<TreatmentForDizziness />}
          />
          <Route
            path="/treatment-for-vomiting"
            element={<TreatmentForVomiting />}
          />
          <Route
            path="/treatment-for-loose-motion"
            element={<TreatmentForLooseMotion />}
          />
          <Route
            path="/doctor-for-pain-management"
            element={<DoctorForPainManagement />}
          />
          <Route
            path="/blood-pressure-doctor"
            element={<BloodPressureDoctor />}
          />
          <Route
            path="/doctor-for-hypertension"
            element={<DoctorForHypertension />}
          />
          <Route
            path="/doctor-for-stomach-issues"
            element={<DoctorForStomachIssues />}
          />
          <Route
            path="/wound-dressing-at-home"
            element={<WoundDressingAtHome />}
          />
          <Route path="/iv-treatment-at-home" element={<IvTreatmentAtHome />} />
          {/* <Route path='/injection-at-home' element={<InjectionAtHome/>} /> */}
          <Route
            path="/full-body-health-checkup"
            element={<FullBodyHealthCheckup />}
          />
          <Route
            path="/general-health-checkup"
            element={<GeneralHealthCheckup />}
          />
          <Route
            path="/doctor-for-minor-injuries"
            element={<DoctorForMinorInjuries />}
          />
          <Route
            path="/doctor-for-animal-bite"
            element={<DoctorForAnimalBite />}
          />
          {/* <Route path='/contact-us' element={<ContactUs/>} /> */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          {/* <Route path='/terms-conditions/' element={<TermsConditions/>} /> */}
          <Route path="/terms-conditions/" element={<TermsConditions />} />
          <Route
            path="/pediatrician-at-home-in-60-minutes"
            element={<PediatricianAtHomeIn60Minutes />}
          />

          {/* <Route path='/blog'/>  */}

          <Route path="/performance/google" element={<PerformanceGoogle />} />
          <Route
            path="/performance/physician"
            element={<PerformancePhysician />}
          />

          <Route
            path="/emergency-home-visit-doctor-delhi"
            element={<EmergencyHomeVisitDoctorServiceinDelhi />}
          />
          <Route
            path="/emergency-home-visit-doctor-gurgaon"
            element={<EmergencyHomeVisitDoctorServiceinGurgaon />}
          />
          <Route
            path="/emergency-home-visit-doctor-bangalore"
            element={<EmergencyHomeVisitDoctorServiceinBangalore />}
          />

          <Route path="/partnership/plum" element={<PartnershipPlum />} />
          <Route
            path="/general-physicians-home-in-yamuna-vihar-delhi"
            element={<GPhysiciansYamunaViharDelhi />}
          />
          <Route
            path="/general-physicians-at-home-in-shahdara-delhi"
            element={<GPhysiciansShahdaraDelhi />}
          />
          <Route
            path="general-physicians-home-in-sarita-vihar-delhi"
            element={<GPhysiciansSaritaViharDelhi />}
          />
          <Route
            path="/rajouri-garden-general-physicians-at-home"
            element={<GPhysiciansRajouriGardenDelhi />}
          />
          <Route
            path="/punjabi-bagh-general-physicians-at-home"
            element={<GPhysiciansPunjabiBaghDelhi />}
          />
          <Route
            path="/general-physicians-at-home-preet-vihar-delhi"
            element={<GPhysiciansPreetViharDelhi />}
          />
          <Route
            path="/patel-nagar-general-physicians-at-home"
            element={<GPhysiciansPatelNagarDelhi />}
          />
          <Route
            path="/najafgarh-general-physicians-home"
            element={<GPhysiciansNajafgarh />}
          />
          <Route
            path="/kapashera-general-physicians-at-home"
            element={<GPhysicianskapashera />}
          />
          <Route
            path="/general-physicians-at-home-in-gandhi-nagar-delhi"
            element={<GPhysiciansGandhiNagarDelhi />}
          />
          <Route
            path="/dwarka-general-physicians-at-home"
            element={<GPhysiciansDwarka />}
          />
          <Route
            path="/general-physicians-home-in-defence-colony-delhi"
            element={<GPhysiciansDefenceColonyDelhi />}
          />

          <Route
            path="/general-physicians-home-in-alipur"
            element={<GPhysiciansAlipur />}
          />
          <Route
            path="/general-physicians-home-in-model-town"
            element={<GPhysiciansModelTown />}
          />
          <Route
            path="/general-physicians-home-in-narela"
            element={<GPhysiciansHomeNarela />}
          />
          <Route
            path="/general-physicians-home-in-civil-lines"
            element={<GPhysiciansCivilLines />}
          />
          <Route
            path="/general-physicians-home-in-saket"
            element={<GPhysiciansHomeSaket />}
          />
          <Route
            path="/general-physicians-home-in-hauz-khas"
            element={<GPhysiciansHomeHauzkhas />}
          />

          <Route
            path="/general-physicians-home-in-mehrauli"
            element={<GPhysiciansHomeMehrauli />}
          />
          <Route
            path="/general-physicians-home-in-defence-colony"
            element={<GPhysiciansHomedefencecolony />}
          />
          <Route
            path="/general-physicians-home-in-kanjhawala"
            element={<GPhysiciansHomeKanjhawala />}
          />
          <Route
            path="/general-physicians-home-in-kalkaji"
            element={<GPhysiciansHomeKalkaji />}
          />
          <Route path="/booking" element={<BookingForm />} />
          <Route path="/booking-v2" element={<BookingFormNewFlowv2 />} />
          <Route path="/new-booking" element={<BookingFormNewFlow />} />
          <Route path="/:city/booking-v3" element={<Bookingv3WithOTP />} />
          <Route path="/booking-v3" element={<Bookingv3WithOTP />} />
          {/* <Route path='/booking-v4' element={<Bookingv3WithOTP />} /> */}
          <Route path="/:city/new-booking" element={<BookingFormNewFlow />} />
          <Route
            path="/new-booking/checkout"
            element={<BookingFormNewFlow />}
          />
          <Route path="/booking/plum" element={<BookingForm />} />
          <Route path="/:city/booking" element={<BookingForm />} />
          <Route path="/booking/confirmation" element={<PaymentSuccess />} />
          <Route path="/booking/plum-success" element={<PaymentSuccess />} />

          <Route path="/blog/spf-in-monsoon" element={<SPFInMonsoon />} />

          <Route path="/blog" element={<BlogHome />} />
          <Route
            path="/blog/benefits-of-regular-doctor-visits"
            element={<BenefitsRegularDoctorVisits />}
          />
          <Route
            path="/blog/best-dandruff-fighting-routine"
            element={<BestDandruffFightingRoutine />}
          />
          <Route
            path="/blog/breastfeeding-perfect-gift-mom-baby"
            element={<BreastFeedingNature />}
          />
          <Route
            path="/blog/caring-for-the-elderly-at-home"
            element={<CaringElderly />}
          />
          <Route
            path="/blog/causes-of-dandruff-and-itchy-scalp"
            element={<UnderstandingtheCausesofDandruffandItchyScalp />}
          />
          <Route
            path="/blog/comprehensive-guide-general-health-checkup"
            element={<ComprehensiveGuideGeneralHealthCheckUp />}
          />
          <Route
            path="/blog/doctor-at-home-services-noida"
            element={<BenefitsDoctorHomeServicesNoida />}
          />
          <Route
            path="/blog/find-reliable-home-visit-doctors-guide"
            element={<FindReliableHomeVisitDoctorsGuide />}
          />
          <Route
            path="/blog/fueling-your-workday"
            element={<FuelingYourWorkday />}
          />
          <Route
            path="/blog/heatwave-alert-5-tips-to-beat-the-heat"
            element={<HeatwaveAlertExpertTipstoBeattheHeat />}
          />
          <Route
            path="/blog/herbal-remedies-cough"
            element={<HerbalRemediesforCough />}
          />
          <Route
            path="/blog/home-remedies-dry-cough"
            element={<HerbalRemediesforDRYCough />}
          />
          <Route
            path="/blog/how-to-control-blood-pressure"
            element={<HowtoControlBloodPressure />}
          />
          <Route
            path="/blog/importance-of-doctor-appointments"
            element={<ImportanceSchedulingAttendingDoctorAppointments />}
          />
          <Route
            path="/blog/iv-therapy-at-home-safety-benefits"
            element={<IVTherapyatHomeSafetyandBenefitsExplained />}
          />
          <Route
            path="/blog/mastering-monsoon-health-expert-tips"
            element={<MasteringMonsoonHealthExpertTips />}
          />
          <Route
            path="/blog/navigate-bp-issues-monitor-blood-pressure"
            element={<NavigateBPIssues />}
          />
          <Route
            path="/blog/post-surgery-recovery-tips"
            element={<PostSurgeryRecoveryTips />}
          />
          <Route
            path="/blog/prescription-medications-dry-cough"
            element={<PrescriptionMedicationsforDryCough />}
          />
          <Route
            path="/blog/roles-of-a-general-physician"
            element={<RoleofGeneralPhysicians />}
          />
          <Route path="/blog/spf-in-monsoon" element={<SPFInMonsoon />} />
          <Route
            path="/blog/stay-safe-this-summer-choose-who-approved-ors"
            element={<StaySafethisSummer />}
          />
          <Route
            path="/blog/support-during-seizures"
            element={<DosandDontsforSupportingSomeoneDuringSeizures />}
          />
          <Route
            path="/blog/tackle-idiot-syndrome"
            element={<TackleIdiotSyndrome />}
          />
          <Route
            path="/blog/tips-finding-best-home-visit-doctors"
            element={<TipsforFindingtheBestHomeVisitDoctors />}
          />
          <Route
            path="/blog/tips-to-relief-from-high-fever"
            element={<TipsToReliefFromHighFever />}
          />
          <Route
            path="/blog/top-10-home-remedies-for-dandruff-treatment"
            element={<Top10HomeRemediesforEffectiveDandruffTreatment />}
          />
          <Route
            path="/blog/top-home-remedies-for-dandruff"
            element={<TopHomeRemediesforDandruff />}
          />
          <Route
            path="/blog/understanding-dry-cough"
            element={<UnderstandingDryCough />}
          />
          <Route
            path="/blog/understanding-fever-when-to-consult"
            element={<UnderstandingFeverWhentoVisitDoctor />}
          />
          <Route
            path="/blog/vector-borne-diseases"
            element={<VectorBorneDiseases />}
          />
          <Route path="/blog/what-is-diabetes" element={<WhatisDiabetes />} />
          <Route
            path="/blog/why-every-family-needs-general-physician"
            element={<WhyEveryFamilyNeedsGeneralPhysicianEssentialGuide />}
          />
          <Route
            path="/blog/top-10-benefits-home-visit-doctors"
            element={<Top10BenefitsofHomeVisitDoctors />}
          />
          <Route path="/locations/store-locator" element={<StoreLocator />} />
          <Route
            path="/blog/sore-throat-natural-home-remedies"
            element={<SoreThroatNaturalHomeRemedies />}
          />
          <Route
            path="/blog/top-7-remedies-ease-throat-ache-at-home"
            element={<Top7RemediestoEaseThroatAche />}
          />
          <Route
            path="/blog/guide-healing-throat-infections-naturally"
            element={<GuidetoHealingThroatInfectionsNaturally />}
          />
          <Route
            path="/blog/goodbye-to-throat-pain-home-remedies"
            element={<GoodbyetoThroatPainTrustedHomeRemedies />}
          />
          <Route
            path="/blog/acidity-home-remedies-fast-natural-relief"
            element={<AcidityHomeRemediesFastNaturalRelief />}
          />
          <Route
            path="/blog/hyper-acidity-home-remedies-immediate-relief"
            element={
              <HyperAcidityHomeRemediesPowerfulFixesforImmediateRelief />
            }
          />
          <Route
            path="/blog/home-remedies-heartburn-acid-reflux-relief"
            element={<FastActingHomeRemediesHeartburnAcidReflux />}
          />
          <Route
            path="/blog/acidity-symptoms-early-warning-signs"
            element={<CommonAciditySymptomsHowtoSpotThemEarly />}
          />
          <Route path="/truecaller" element={<MobileInputWithTruecaller />} />

          <Route path="/book-doctor" element={<BookDoctor />} />
          <Route path="/book-doctor/step-1" element={<GetMobileNumber />} />
          <Route path="/book-doctor/step-2" element={<PersonalInfoForm />} />
          <Route path="/book-doctor/step-3" element={<BookingPage />} />
          <Route
            path="/user/profile"
            element={
              <PrivateRoute>
                <div>Protected</div>
              </PrivateRoute>
            }
          />
          <Route
            path="/book-doctor/step1"
            element={
              <PrivateRoute>
                <div>Protected</div>
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>

    {/* <Footer id="footer-aboutUs" /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
