import React, { useEffect, useRef, useState } from "react";
import SearchInput from "./SearchInput";
import { GoogleMap, Marker } from "@react-google-maps/api";
import DetectIcon from "../../Icons/DetectIcon";

const MapComponent = ({
  crds,
  query,
  setQuery,
  setCrds,
  setIsMapOpen,
  setMapAddressChange,
  mapAddressChange,
  city,
  setCity,
}) => {
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(false);
  const mapTRef = useRef(null);

  const fetchForMap = async (position) => {
    const { latitude, longitude } = position.coords;
    setCrds({ lat: latitude, lng: longitude });

    try {
      const response = await fetch(
        `https://kynohealth.com/ssr-api/location?latitude=${latitude}&longitude=${longitude}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const userLocation = data.results[0].formatted_address;

        const city = data?.results[0].address_components.find((e) =>
          e.types.includes("locality")
        );
        if (city) {
          setCity(city.long_name);
        }

        setQuery(userLocation);
      } else {
        console.log("Unable to fetch location details.");
      }
    } catch (err) {
      console.log("Failed to fetch location data from Google Maps.");
    }
  };

  const handleMarkerDragEnd = (e) => {
    const clickedLatLng = e.latLng;

    // Use the map instance to pan to the clicked location
    if (map && clickedLatLng) {
      map.panTo(clickedLatLng); // Smoothly pans the map to the clicked coordinates
    }
    mapTRef.current && clearTimeout(mapTRef.current);
    mapTRef.current = setTimeout(() => {
      setCrds({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
      fetchForMap({
        coords: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
      });
    }, 500);
  };

  const locateME = () => {
    navigator.geolocation.getCurrentPosition(
      (coords) => {
        setCrds({
          lat: coords.coords.latitude,
          lng: coords.coords.longitude,
        });
        fetchForMap(coords);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    return () => {
      setMapAddressChange(false);
    };
  }, []);

  return (
    <>
      <div
        style={{
          top: "0",
          height: "100dvh",
          maxWidth: "500px",
          width: "100%",
          position: "fixed",
          display: "flex",
          alignItems: "end",
          zIndex: "999",
          backdropFilter: "blur(3px) brightness(0.5)",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsMapOpen(false);
        }}
      >
        <div
          style={{
            width: "100%",
            background: "#fff",
            // borderRadius: "12px 12px 0 0",
            overflow: "hidden",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {" "}
          <div
            style={{
              padding: "20px 12px 12px",
              position: "absolute",
              top: "0px",
              zIndex: "1010",
              width: "100%",
            }}
          >
            <SearchInput
              query={query}
              setQuery={setQuery}
              setCrds={setCrds}
              fetchLocationFromLatLong={fetchForMap}
              styles={{ background: "#fff", padding: "14px" }}
            />
          </div>
          {
            <GoogleMap
              options={{
                zoomControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                // gestureHandling: "greedy",
                disableDoubleClickZoom: false,
              }}
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
              center={{
                lat: crds?.lat || 28.64,
                lng: crds?.lng || 77.21,
              }}
              zoom={18}
              onLoad={(map) => {
                map.setOptions({
                  disableDefaultUI: true,
                  zoomControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_CENTER,
                  },
                });
                setMap(map);
                setTimeout(() => {
                  setMarkerPosition(true);
                }, 300);
              }}
              onUnmount={() => {
                setTimeout(() => setMarkerPosition(false), 300);
              }}
              onClick={handleMarkerDragEnd}
            >
              {markerPosition && (
                <Marker
                  onDragEnd={handleMarkerDragEnd}
                  animation={window.google.maps.Animation.DROP}
                  draggable
                  visible
                  position={{
                    lat: crds?.lat || 28.64,
                    lng: crds?.lng || 77.21,
                  }}
                />
              )}
            </GoogleMap>
          }
          <div style={{ padding: "24px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
            >
              <button
                style={{
                  position: "absolute",
                  top: "-45px",
                  left: "50%",
                  transform: "translate(-50%,-100%)",
                  border: "1.5px solid #179d82",
                  color: "#179d82",
                  padding: "6px 22px",
                  borderRadius: "6px",
                  background: "#fff",
                  fontSize: "14px",
                  boxShadow: "1px 1px 14px #33333399",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                  fontWeight: "600",
                }}
                onClick={locateME}
              >
                <DetectIcon size="15" /> Locate me
              </button>
              <p style={{ fontWeight: "bold" }}>{city}</p>
              {/* {query && (
                <button
                  style={{
                    color: "#179D82",
                    background: "#fff",
                    padding: "10px 16px",
                    borderRadius: "10px",
                    border: "1px solid #ddd",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    setMapAddressChange((e) => !e);
                  }}
                >
                  {mapAddressChange ? "Save" : "Change"}
                </button>
              )} */}
            </div>
            {mapAddressChange ? (
              <input
                ref={(e) => e?.focus()}
                style={{ marginBlock: "15px" }}
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            ) : (
              <p style={{ marginBlock: "15px" }}>
                {query ? query : "Please Choose the Location"}
              </p>
            )}
            <button
              disabled={!query}
              onClick={() => {
                setIsMapOpen(false);
              }}
              style={{
                background: query ? "#179D82" : "#ccc",
                color: "#fff",
                padding: "10px 16px",
                borderRadius: "10px",
                border: "none",
                cursor: "pointer",
                width: "100%",
                fontWeight: "600",
              }}
            >
              Continue to add address
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapComponent;
