import React from "react";

export default function Completed() {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.5" cy="16.5" r="16.5" fill="url(#paint0_linear_243_347)" />
      <path
        d="M15.0969 21.9985C14.6571 21.9994 14.2324 21.8405 13.9043 21.5522L9.63975 17.8206C9.45614 17.6686 9.30534 17.4818 9.19626 17.2714C9.08719 17.061 9.02205 16.8312 9.0047 16.5956C8.98735 16.36 9.01814 16.1234 9.09525 15.8997C9.17235 15.676 9.29422 15.4698 9.45363 15.2932C9.61305 15.1167 9.80679 14.9734 10.0234 14.8718C10.24 14.7702 10.4751 14.7124 10.7148 14.7018C10.9545 14.6912 11.1939 14.7279 11.419 14.8099C11.644 14.892 11.85 15.0175 12.0249 15.1793L14.9832 17.7661L20.8937 11.5582C21.2195 11.2165 21.6698 11.0161 22.1455 11.0009C22.6213 10.9857 23.0838 11.157 23.4315 11.4772C23.7792 11.7978 23.9833 12.2413 23.999 12.7101C24.0147 13.1789 23.8407 13.6346 23.5153 13.9771L16.4077 21.4402C16.2401 21.6173 16.0372 21.7585 15.8116 21.8548C15.5861 21.9512 15.3427 22.0006 15.0969 22V21.9985Z"
        fill="#FDFFFE"
      />
      <defs>
        <linearGradient
          id="paint0_linear_243_347"
          x1="16.5"
          y1="0"
          x2="16.5"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#179D82" />
          <stop offset="1" stop-color="#0B7963" />
        </linearGradient>
      </defs>
    </svg>
  );
}
