import React, { useEffect } from "react";
import Failed from "../../Icons/Failed";
import BrandButton from "../BrandButton";


export default function ETAFile({ time = 300, text = "", func }) {
  let savedAppointment = localStorage.getItem("savedAppointment")
  savedAppointment = savedAppointment ? JSON.parse(savedAppointment) : null
  let timeInSec = time
  if (savedAppointment) {
    const { created_at } = savedAppointment
    const appointmentStartTime = new Date(created_at);
    const currentTime = new Date();

    // Calculate the difference in milliseconds
    let diffMs = currentTime - appointmentStartTime;
    let diffMinutes = diffMs / (1000 * 60);
    timeInSec = (diffMinutes) * 60 > time ? 0 : (diffMinutes) * 60
  }

  // Convert milliseconds to minutes
  const [retry, setRetry] = React.useState(timeInSec);
  useEffect(() => {
    const interval = setInterval(() => {
      setRetry((retry) => (retry > 0 ? retry - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleNavigation = () => {
    if (func){
      func()
    }else{
      window.location.href ="/book-doctor/step-2"
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 20px",
          textAlign: "center",
          marginInline: "auto",
          maxWidth: "400px",
          gap: "16px",
        }}
      >
        <Failed />

        <p style={{ fontSize: "14px" }}>
          {text
            ? text
            : "We are experiencing heavy demand at the moment, please try again after some time."}
        </p>
        {retry > 0 ? (
          <div
            style={{ fontSize: "12px", color: "#179D82", fontWeight: "bold" }}
          >
            {`Try Again in ${Math.floor(retry / 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}:${(retry % 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}`}
          </div>
        ) : (
          <BrandButton
            onClick={() => {
              handleNavigation();
            }}
            style={{ width: "100%", maxWidth: "280px", marginInline: "auto" }}
            label={"Try Again"}
          />
        )}
        {/* <button style={{ border: "none", padding: "16px", background: "#fff" }}>
          Cancel
        </button> */}
      </div>
    </div>
  );
}
