import React from "react";

export default function Failed() {
  return (
    <svg
      width="83"
      height="82"
      viewBox="0 0 83 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5 2.5625C20.2825 2.5625 3.0625 19.7825 3.0625 41C3.0625 62.2175 20.2825 79.4375 41.5 79.4375C62.7175 79.4375 79.9375 62.2175 79.9375 41C79.9375 19.7825 62.7175 2.5625 41.5 2.5625ZM41.5 64.0625C38.6813 64.0625 36.375 61.7562 36.375 58.9375C36.375 56.1188 38.6813 53.8125 41.5 53.8125C44.3187 53.8125 46.625 56.1188 46.625 58.9375C46.625 61.7562 44.3187 64.0625 41.5 64.0625ZM47.3681 24.3181L45.6513 44.8694C45.4719 47.0219 43.6781 48.6875 41.5 48.6875C39.3219 48.6875 37.5281 47.0219 37.3488 44.8694L35.6319 24.3181C35.35 20.8844 38.0406 17.9375 41.5 17.9375C42.274 17.9375 43.0404 18.0899 43.7554 18.3861C44.4705 18.6823 45.1202 19.1165 45.6675 19.6637C46.2148 20.211 46.6489 20.8607 46.9451 21.5758C47.2413 22.2909 47.3937 23.0573 47.3937 23.8313C47.3937 23.985 47.3938 24.1644 47.3681 24.3181Z"
        fill="#FF6174"
      />
    </svg>
  );
}
