import React from "react";

function HowItWorks() {
  const steps = [
    {
      description:
        "Kyno Health, 1094, Huda Colony, Sector 46, Gurugram, Haryana 122018",
    },
    // {
    //     description: 'HUAMI TECHNOLOGIES PRIVATE LIMITED, H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL, GURGAON, Gurgaon, Haryana, India, 122001',
    // },
    // {
    //     description:
    //         '449/434/09 ,Behind Kanti Sweets, Bellandur Doddakannelli Road, Outer Ring Rd, Bengaluru, Karnataka 560103',
    // },
    // {
    //     description:
    //         'Opp Unlimited Showroom, 501, Chinmaya Mission Hospital Rd, Indira Nagar, Stage 1, Bengaluru, Karnataka 560038',
    // },
  ];

  return (
    <section className="new_contact_us">
      <h2 className="kyno-section_title">Address</h2>
      <div className="feature-card-section">
        {steps.map((step, index) => (
          <div className="feature-card-section">
            <div className="feature-card">
              <div className="content">
                <p>{step.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default HowItWorks;
