import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Helmet } from 'react-helmet'
import logo from "../../utilities/images/home_v2/logo.png"
import Nav from '../home_page_v2/Nav/index'
import Footer from '../lendingPage/Footer';
// import Footer from '../home_page_v2/Footer/index'
import Header from "../lendingPage/Header";
import DoctorScreen from "./ExpressBooking/doctorScreen";

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let bookedSlot = searchParams.get("bookedSlot")
    let urlAppId = searchParams.get("appId") || ''
    const isPlumPage = !!window.location.href.includes("plum")
    const [isLoading, setIsLoading] = useState(false);
    const [appId, setAppId] = useState(urlAppId)
    const onReset = () => {
        window.location.href = isPlumPage ? '/' : '/';
    }

    const [ETA, setETA] = useState(0);
    const [appointment, setAppointment] = useState({})
    
    const handleFetchAppointmentDetails = async () => {
        
        const myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        myHeaders.append("token", sessionStorage.getItem('token'));
        setIsLoading(true)
        try {
            
        let appointmentDetails = await fetch(process.env.REACT_APP_KMS_BASE_URL + `/api/customers/appointments/${appId}`, {
            headers: myHeaders
        })
        
        appointmentDetails = await appointmentDetails.text()
        appointmentDetails =  JSON.parse(appointmentDetails)
        setAppointment(appointmentDetails)
        console.log("appointmentDetails", appointmentDetails)
        // let appointment = appointmentDetails
        if (appointmentDetails && appointmentDetails.appointment_start_time) {
            let ETA = 0
            if (appointmentDetails.appointment_start_time) {
                const currentTime = new Date()
                const appointmentStartTime = new Date(appointmentDetails.appointment_start_time)
                const diff = appointmentStartTime - currentTime
                ETA = Math.floor(diff / 60000)
                // ETA = ETA >= 0 ? ETA : 0
            }
            
            setETA(ETA < 5 ? 5 : ETA)
            

        } else {
            setAppId(null)
        }
    }
        catch (error) {
            setAppId(null)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        if (!appId) { return }
        handleFetchAppointmentDetails()
    }, [appId])

    useEffect(() => {
        if (!appId) return
        if (ETA <= 0) return;

        const timer = setInterval(() => {
          setETA(prevTime => prevTime - 1);
        }, 60000); // Subtract 1 every 60,000 ms (1 minute)
    
        return () => clearInterval(timer); // Cleanup on unmount
            
    }, [ETA])


    useEffect(() => {
        if (bookedSlot) {
            localStorage.setItem('bookedSlot', bookedSlot);
        }
    }, [bookedSlot])
    if (isLoading) {
        return <div>Loading...</div>;
    }
    console.log({ETA})
    return (
        <div>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

                <title>Medical Consultation | Best Physician Doctor in Noida</title>
                <meta name="description" content="Looking for a skilled general physician in Noida? Our experienced doctors provide expert medical consultation services and personalized treatment." />
                <link rel="canonical" href="https://www.kynohealth.com/" />

                <meta property='og:image' content={logo} />
                
                <meta name="google-site-verification" content="EADXQCFm27CfkWKQlFSbbLUSV6Q1I-yh-8taT0qxR-s" />
            </Helmet>
            <Header hideBookingCta={true} />
            <div className="booking_thankyou" style={{ textAlign: 'center', padding: '20px 10px' }}>
                <Typography variant="h5" color="primary"><CenteredTickCircle /></Typography>
                <Typography variant="h6"><strong>Booking Successful!</strong></Typography>
                {bookedSlot && <Typography ><small>on {convertToLocalTime(bookedSlot)}</small></Typography>}
                {appId ? <Typography style={{ fontSize: '14px', color: "#00000080", textAlign:"left", margin: "0 auto" }}>
                    <div className="booking_thanyou_slot_details" style={{
                            // background: "#edcaaa",
                            // padding: "10px",
                            // borderRadius: "10px",
                            fontWeight: "bold",
                            color: "black",
                            textAlign: "center",
                            maxWidth: "400px",
                            margin: "0 auto",
                    }}>
                        <Typography variant="h6"><strong>Appointment ID - {appId}</strong></Typography>
                        {/* <div>Appointment ID - {appId}</div> */}
                        {/* <div>Location - {appointment.appointment_address}</div> */}
                        <div style={{
                            background: "rgb(68 157 131)",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            color: "#fff",  
                            fontSize: "18px",
                            lineHeight: "40px",
                            width: "300px",
                            margin: "20px auto",
                        }}>{(ETA > 0) ? `Doctor's ETA - ${ETA} minutes` : "Doctor Arriving soon"}</div>
                    </div>
                    <div style={{
                        textAlign: "center",
                        margin: "40px 0",
                        display: "none"
                    }}>
                        <div style={{
                            fontWeight: "bold",
                            color: "black",
                            fontSize: "16px",
                            marginBottom: "5px",
                        }}>Sit back and Relax!</div>
                        <div style={{
                            
                        }}>
                        • You will receive update on whatsapp when the doctor has reached your location.<br />
                        • For any immediate need or cancellation, reach us at 9953104104
                        </div>
                    </div>
                    <DoctorScreen appointment={appointment} />
                    <div style={{
                        textAlign: "center",
                        margin: "20px 0 0px",
                        // display: "none"
                        fontSize: "15px",
                        color: "rgb(33, 37, 41)"

                    }}>
                        <div style={{
                            
                        }}>
                        You will get a call from our team shortly to confirm your appointment.
                        </div>
                    </div>
                </Typography>
                : <Typography style={{ fontSize: '14px', color: "#00000080", textAlign:"left", width:"90%", margin: "0 auto",  maxWidth: "500px" }}>
                    <ul>
                    <li>Thank you for booking with Kyno! Your details have been received.</li>
                    <li>You will receive Doctor's profile and Doctor's visit time shortly.</li>
                    <li>The doctor will reach fully equipped to address your symptoms.</li>
                    </ul>
                </Typography>
                }
                <Button variant="contained" hidden={!!appId} color="primary" onClick={() => onReset()} style={{
                    margin: "20px auto",
                    marginTop: '20px',
                    maxWidth: "343px",
                    fontWeight: "600",
                    padding: "0px 16px",
                    gap: "8px",
                    borderRadius: "10px",
                    background: "#FB9F40",
                    color: "#fff",
                    lineHeight: "40px"
                }}>
                    Back To Homepage
                </Button>
            </div>
            <Footer isBookSlot={true}  isHideSticky={true} isPaidPage={true} />
        </div>
    );
};

const CenteredTickCircle = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '30px', // Full height to center vertically
            }}
        >
            <Box
                sx={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    border: '10px solid rgb(68 157 131 / 20%)', // Outer circle color
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        border: '10px solid #9C0D381', // Inner circle color
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CheckCircleIcon sx={{ fontSize: '40px', color: '#449d83', width: "100%", height: "100%" }} />
                </Box>
            </Box>
        </Box>
    );
};

const convertToLocalTime = (utcDateString) => {
    const utcDate = new Date(utcDateString);

    // Extract the parts needed for formatting
    const day = utcDate.getDate();  // Day of the month (e.g., 15)
    const month = utcDate.toLocaleString('default', { month: 'long' });  // Full month name (e.g., August)
    const hours = utcDate.getHours() % 12 || 12;  // Convert 24-hour to 12-hour format (e.g., 11)
    const minutes = String(utcDate.getMinutes()).padStart(2, '0');  // Pad minutes with leading zero if needed
    const ampm = utcDate.getHours() >= 12 ? 'PM' : 'AM';  // Determine AM/PM

    // Format the date as "15 August, 11:22 PM"
    return `${day} ${month}, ${hours}:${minutes} ${ampm}`;

    // return formattedDate;
};


export default PaymentSuccess