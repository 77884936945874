import React, { useEffect, useState } from "react";
import "../HomeVisit/Css/style.scss";
import { useNavigate } from "react-router-dom";
import docConsult from '../HomeVisit/Images/doctorInteraction.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
// import BookingFormNewFlow from "../../booking/landing_10min";
import { Button } from "react-bootstrap";

function HomeVisit() {
  const navigate = useNavigate();

  const goToBooking = () => {
    navigate("/booking-details");
  };

  return (
    <div className="visit_container">
      <div className="visit_card">
        <h1 className="visit_title">
          Expert doctor at home<br />
          in <span className="highlight">20 minutes</span>
        </h1>
        {/* <p className="description">
          Get a home visit by our experienced & dedicated General Physicians who
          are available <span className="highlight">24×7</span>.
        </p> */}

        {/* <button className="book-button" onClick={goToBooking}>Book a home visit</button> */}
      </div>
      <div className="availability">
          Available in <span className="cities">Delhi NCR & Bangalore</span>
        </div>
        <Button onClick={() => navigate("/book-doctor/step-1")} style={{
          color: "white",
          border: "unset",
          backgroundColor: "#ffa500",
          marginBottom: "10px",
          zIndex: 1,
          fontSize: "20px",
          fontWeight: "bold",
          lineHeight: "40px",
          width: "265px",
        }}>Book Home visit</Button>
        {/* <div style={{ display: "none" }} className="onlyDesktop">
          <BookingFormNewFlow customSrNumber="9953104104" fromHomepage={true} />
        </div>
      <div style={{ display: "none" }} className="doc_image_section">
        <BookingFormNewFlow customSrNumber="9953104104" fromHomepage={true} />
      </div> */}
    </div>
  );
}


const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        background: "white",
        borderRadius: "50%",
        padding: "0 5px 0 0px",
        position: "absolute",
        right: "10px", // Positioned inside the slider
        top: "50%", // Vertically center
        transform: "translateY(-50%)", // Correct vertical alignment
        zIndex: 1,
        opacity: 0.5
      }}
      onClick={onClick}
    />
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        background: "white",
        borderRadius: "50%",
        padding: "0 0px 0 3px",
        position: "absolute",
        left: "10px", // Positioned inside the slider
        top: "50%", // Vertically center
        transform: "translateY(-50%)", // Correct vertical alignment
        zIndex: 1,
        opacity: 0.5
      }}
      onClick={onClick}
    />
  );
};


const SlickCarousel = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768); // You can change the breakpoint as needed
      };

      // Initial check
      handleResize();

      // Listen for window resize
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const settings = {
      dots: false, // Removes the dots
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true, // Enables auto-scroll
      autoplaySpeed: 3000, // Slide change interval (3 seconds)
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
  };
  return (
      <div className='carousel-container'>
          <Slider {...settings}>
                  <div>
                      <img src="/images/1.2.jpg" alt="slide 1" />
                  </div>
                  <div>
                      <img src="/images/2.2.jpg" alt="slide 2" />
                  </div>
                  <div>
                      <img src="/images/3.2.jpg" alt="slide 1" />
                  </div>
                  <div>
                      <img src="/images/4.2.jpg" alt="slide 2" />
                  </div>
                  <div>
                      <img src="/images/5.2.jpg" alt="slide 1" />
                  </div>
                  <div>
                      <img src="/images/6.2.jpg" alt="slide 2" />
                  </div>
              </Slider>
      </div>
  );
};

export default HomeVisit;
